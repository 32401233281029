import { useCompanyActions } from 'features/company/api/useCompanyActions';
import { useInnCompany } from 'features/company/api/useCompanyInn';
import { useCompanyTypes } from 'features/company/api/useCompanyTypes';
import { useGetUser } from 'features/profile/api/useGetUser';
import { FC, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useAlertMessage } from 'shared/hooks';
import AuthService from 'shared/services/auth/auth.service';
import { TCompany } from 'shared/services/types';
import { MultiSelectCompanyTypes } from 'shared/ui/form/custom-select';
import { FormWrapper } from 'shared/ui/form/form-wrapper';
import { Input } from 'shared/ui/form/input';
import { ReactSelect } from 'shared/ui/form/react-select';
import './ProfileInfoCompany.scss';
import { optionsINN } from './contants';

interface IProps {
	companyInfo?: TCompany.Response;
}

const ProfileInfoCompany: FC<IProps> = ({ companyInfo }) => {
	const { userData } = useGetUser();
	const methods = useForm({ mode: 'all' });
	const {
		control,
		formState: { errors }
	} = methods;

	const countyDataInn = useMemo(() => {
		return optionsINN.find(item => item.value === userData?.country?.id);
	}, [userData]);

	const [isClick, setIsClick] = useState(false);
	const { addAlertMessage } = useAlertMessage();
	const { createCompany, updateCompany } = useCompanyActions();
	const { companyTypesData } = useCompanyTypes();

	const { getInfoCompany } = useInnCompany({
		inn: methods.watch('inn'),
		country: methods.watch('country')?.value
	});

	const handleTypeSelect = (selectedOptions: TCompany.CompanyType[]) => {
		const businessTypesIds = selectedOptions.map(i => {
			return i.id;
		});

		methods.setValue('business_types_ids', businessTypesIds);
	};

	useEffect(() => {
		const { isPending, data, isError, error } = getInfoCompany;
		if (!isClick) return;
		if (!isPending && isError) {
			addAlertMessage(
				error || 'Произошла ошибка при подгрузке данных от налоговой',
				'error'
			);
		} else if (!isPending && data) {
			const updateDate = {
				...methods.getValues(),
				...data,
				address: data.juridical_address
			};

			methods.reset(updateDate);
			addAlertMessage(
				'Данные успешно получены, заполните оставшиеся поля и отправьте данные о своей компании',
				'success'
			);
		}
	}, [getInfoCompany.data, getInfoCompany.error, getInfoCompany.isPending]);

	const handlerErrorResponse = (error: any) => {
		if ([400, 422].includes(error.response.status)) {
			const errorData = error.response.data;

			for (const key in errorData) {
				const valueError = Array.isArray(errorData[key])
					? String(errorData[key])
					: errorData[key];

				methods.setError(key, { message: valueError });
			}
		} else {
			if (error?.response?.data?.detail) {
				addAlertMessage(error, 'error');
			}
		}
	};

	const sendCompanyInfo = async () => {
		methods.trigger();

		const newData = {
			...methods.getValues(),
			correspond_languages_ids: [],
			speak_languages_ids: []
		};

		if (companyInfo) {
			try {
				const dataCompanyUpdate = {
					...newData,

					english_name: methods.getValues('english_name')
				};
				// @ts-ignore
				await updateCompany.mutateAsync(dataCompanyUpdate);
				addAlertMessage('Сохранено', 'success');
			} catch (error: any) {
				addAlertMessage(error, 'error');
				handlerErrorResponse(error);
			}
		} else {
			try {
				if (!userData?.country?.id) {
					return addAlertMessage(
						'Заполните поле "Страна проживания" ',
						'error'
					);
				}
				const createData = {
					...newData,
					...methods.getValues()
				};

				//@ts-ignore
				await createCompany.mutateAsync({
					...createData,
					//@ts-ignore
					country_id: userData?.country.id
				});
				addAlertMessage('Сохранено', 'success');
				AuthService.refresh();
			} catch (error: any) {
				handlerErrorResponse(error);
			}
		}
	};

	useEffect(() => {
		if (companyInfo) {
			methods.reset({ ...companyInfo, country: countyDataInn });
		}
	}, [companyInfo]);

	const disabledField = !!(!userData?.is_boss && companyInfo);

	return (
		<FormWrapper
			methods={methods}
			onSubmit={sendCompanyInfo}
			className='profilecompany-form'
		>
			{disabledField && (
				<p style={{ marginBottom: '2rem' }} className='profile-message'>
					Вы не владелец компании. Изменить данные нельзя.
				</p>
			)}
			<div className='select-block'>
				<MultiSelectCompanyTypes
					disabled={disabledField}
					onChange={handleTypeSelect}
					multiSelectOptions={companyTypesData || []}
					defaultRef={companyInfo?.inn}
				/>
			</div>
			<ReactSelect
				className='select-gender'
				control={control}
				name='country'
				label='Страна выдачи налогового номера'
				error={errors}
				options={optionsINN}
			/>
			<div>
				<div className='inputs-row'>
					<Input
						placeholder='Введите налоговый номер'
						control={control}
						label='Введите налоговый номер'
						name='inn'
						disabled={disabledField}
						error={errors}
						required
						rules={{
							required: 'Обязательно поле для заполнения',
							minLength: {
								value: 10,
								message: 'Налоговый номер должен быть  не менее 10 символов'
							},
							maxLength: {
								value: 12,
								message: 'Налоговый номер должен быть не более 12 символов'
							}
						}}
					/>

					{!disabledField && methods.watch('country')?.value !== 'UKR' && (
						<div className='input-submit-block'>
							<button
								className='btn'
								type='button'
								disabled={disabledField || getInfoCompany.isFetching}
								onClick={() => {
									if (methods.watch('country')) {
										setIsClick(true);
										getInfoCompany.refetch();
									} else {
										methods.setError('country', {
											message: 'Необходимо выбрать страну'
										});
									}
								}}
							>
								Заполнить
							</button>
						</div>
					)}
				</div>
			</div>
			<div className='inputs-row'>
				<Input
					placeholder='Наименование'
					control={control}
					label='Наименование'
					name='name'
					disabled={disabledField}
					error={errors}
					required
					rules={{
						required: 'Обязательно поле для заполнения'
					}}
				/>
				<Input
					placeholder='Наименование на английском'
					control={control}
					label='Наименование на английском'
					name='english_name'
					disabled={disabledField}
					error={errors}
					rules={{
						pattern: {
							message: 'Название должно содержать латинские буквы',
							value: /^[a-zA-Z0-9\s!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/
						}
					}}
				/>
			</div>
			<div className='inputs-row'>
				<Input
					placeholder='Город'
					control={control}
					label='Город'
					name='city'
					disabled={disabledField}
					error={errors}
				/>
				<Input
					placeholder='Адрес'
					control={control}
					label='Адрес'
					name='address'
					disabled={disabledField}
					error={errors}
				/>
			</div>
			<div className='inputs-row'>
				<Input
					placeholder='БИК / SWIFT'
					control={control}
					label='БИК / SWIFT'
					name='bank_code'
					disabled={disabledField}
					error={errors}
				/>
				<Input
					placeholder='Наименование банка'
					control={control}
					label='Наименование банка'
					name='bank_name'
					disabled={disabledField}
					error={errors}
				/>
			</div>

			<Input
				placeholder='Расчетный счет'
				control={control}
				label='Расчетный счет'
				name='settlement_bill'
				disabled={disabledField}
				error={errors}
			/>

			{!disabledField && (
				<div className='block-save block-term'>
					<div className='block-save-left'>
						<button className='btn' type='submit' disabled={disabledField}>
							Сохранить
						</button>
						<div className='terms-div'>
							<input className='terms-button' type='checkbox' required />
							<p>
								Нажав кнопку сохранить вы даете согласие на обработку{' '}
								<Link target='_blank' to='/privacy_policy'>
									персональных данных
								</Link>{' '}
								и подтверждаете, что вы совершеннолетний
							</p>
						</div>
					</div>
				</div>
			)}
		</FormWrapper>
	);
};

export default ProfileInfoCompany;
