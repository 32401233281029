import { useLocation } from 'react-router';
import { useQueryParams } from 'shared/hooks';

export const useHelpRegister = () => {
	const location = useLocation();

	const params = useQueryParams();

	const urlParams = location.pathname.split('/');

	const initialsNew = decodeURIComponent(urlParams[2]).split(' ');
	const fio = decodeURIComponent(urlParams[3]).split(' ');

	const email = initialsNew[0];
	const first_nameNew = fio[0];
	const last_nameNew = fio[1];
	const patronymicNew = fio[2];
	const tokenNew = params?.token;

	return {
		email,
		last_nameNew,
		first_nameNew,
		patronymicNew,
		tokenNew
	};
};
