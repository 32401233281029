import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import PricesService from 'shared/services/prices.service';
import { TPrice } from 'shared/services/types';

export const usePriceSource = (id?: number) => {
	const queryClient = useQueryClient();

	const getSource = useQuery({
		queryFn: () => PricesService.getPriceSource(),
		queryKey: ['get price source'],
		retry: 0,
		select: ({ data }) => data
	});

	const addSource = useMutation({
		mutationFn: (data: TPrice.SourceData) => PricesService.addPriceSource(data),
		mutationKey: ['update price source', id],
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: [`get price source`]
			});
		}
	});

	const updateSource = useMutation({
		mutationFn: (data: TPrice.SourceData) =>
			PricesService.changePriceSource(id!, data),
		mutationKey: ['update price source', id],
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: [`get price source`]
			});
		}
	});

	const deletePriceSource = useMutation({
		mutationFn: () => PricesService.deletePriceSource(id!),
		mutationKey: ['delete price source', id],
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: [`get price source`, 'getHistorySource']
			});
		}
	});

	const uploadPriceSource = useMutation({
		mutationFn: (data: TPrice.Update) =>
			PricesService.uploadPriceSource(id!, data),
		mutationKey: ['uploadPriceSource', id],
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: [`get price source`]
			});
			queryClient.invalidateQueries({
				queryKey: ['getHistorySource']
			});
		}
	});

	const getHistorySource = useQuery({
		queryFn: () => PricesService.getHistorySource(id!),
		queryKey: ['getHistorySource', id],
		retry: 0,
		enabled: !!id,
		select: ({ data }) => data
	});

	const uploadHistorySource = useMutation({
		mutationFn: (history_id: number) =>
			PricesService.uploadHistorySource(id!, history_id),
		mutationKey: ['uploadPriceSource', id],
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: [`getHistorySource`] });
		}
	});

	return {
		updateSource,
		getSource,
		addSource,
		deletePriceSource,
		uploadPriceSource,
		getHistorySource,
		uploadHistorySource
	};
};
