import { AxiosError } from 'axios';
import { useAlert } from 'react-alert';

const strFile = (obj?: Record<string, any>) => {
	if (!obj || typeof obj === 'string') return null;

	const data = Object.values(obj).reduce((acc, item) => {
		if (Array.isArray(item)) {
			return item.reduce((accum, v) => {
				if (typeof v === 'string') return `${accum}${v}\n`;

				const res = Object.values(v);

				return `${accum}${res}\n`;
			}, '');
		}
		return `${acc}${item.join('\n')}\n`;
	}, '');

	return data;
};

function isAxiosError(error: any): error is AxiosError<any> {
	return error.isAxiosError === true;
}
const getMessageText = (
	message: string | string[] | AxiosError<any> | Error
): string => {
	if (Array.isArray(message)) {
		return message.join('\n');
	}

	if (typeof message === 'string') {
		return message;
	}

	if (isAxiosError(message)) {
		const detail = message.response?.data?.detail;
		if (typeof detail === 'string') {
			return detail;
		}

		const fileMessages = message.response?.data?.file?.join('\n');
		if (fileMessages) {
			return fileMessages;
		}

		return (
			strFile(message.response?.data) || message.message || 'Unknown error'
		);
	}

	return message.message || 'Unknown error';
};

export function useAlertMessage() {
	const alert = useAlert();

	const getMessageError = (
		message: string | string[] | AxiosError<any> | Error
	) => {
		//@ts-ignore
		const isServiceError = message?.response?.data;
		if (isServiceError) {
			const messageText =
				typeof isServiceError?.detail === 'string'
					? isServiceError?.detail
					: isServiceError?.detail?.reason;
			return messageText;
		} else {
			const resultMessage = getMessageText(message);
			return resultMessage;
		}
	};
	const addAlertMessage = (
		message: string | string[] | AxiosError<any> | Error,
		type: 'success' | 'info' | 'error' = 'success'
	) => {
		const errorMessage = getMessageError(message);
		alert[type](errorMessage);
	};

	return { addAlertMessage, getMessageError };
}
