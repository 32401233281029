export const fileToBase64 = (file?: File | null): Promise<string | null> => {
	if (!file) {
		return Promise.resolve(null); // Возвращаем промис, который разрешается в null
	}

	return new Promise((resolve, reject) => {
		const reader = new FileReader();

		reader.onloadend = () => {
			// Убираем префикс data URL, если он есть
			const base64String = reader.result?.toString().split(',')[1] || '';
			resolve(base64String);
		};

		reader.onerror = error => {
			reject(error);
		};

		reader.readAsDataURL(file);
	});
};
