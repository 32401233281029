export 	const getQueryParams = (): any => {
  const { search } = location;
  const sliceSearch = decodeURIComponent(search);
  const clearSearch = sliceSearch.replace('?', '');
  const helperArray = clearSearch.split('&');

  const data = helperArray.reduce((acc, item) => {
    const [name, value] = item.split('=');
    return {
      ...acc,
      [name]:
        value?.includes(',') && name !== 'search' ? value.split(',') : value
    };
  }, {});
  return data;
};