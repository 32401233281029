import { toFormData } from 'axios';
import { TSupplier } from './types';

import { axiosInstance } from 'shared/api';
import { getSupplierUrl } from 'shared/api/config';

const SupplierService = {
	async getList(data?: TSupplier.Filter) {
		const toArrayString = (data?: TSupplier.Filter) => {
			if (!data) return data;
			const arr = Object.entries(data).reduce((acc, [key, value]) => {
				if (Array.isArray(value)) {
					return { ...acc, [key]: value.join(',') };
				}
				return { ...acc, [key]: value };
			}, {});
			return arr;
		};

		const response = await axiosInstance.get<TSupplier.Response>(
			getSupplierUrl(``),
			{
				params: toArrayString(data)
			}
		);
		return response;
	},

	async getSupplierBySlug(slug?: string) {
		const response = await axiosInstance.get<TSupplier.DataDetail>(
			getSupplierUrl(`/info/${slug}`)
		);
		return response;
	},

	async createRequest(data: TSupplier.RequestCreate) {
		const response = await axiosInstance.post<TSupplier.RequestDataDetail>(
			getSupplierUrl(`/requests`),
			toFormData(data)
		);
		return response;
	},

	async getRequestPartnersList(data?: TSupplier.GetListRequests) {
		const response = await axiosInstance.get<TSupplier.RequestListResponse>(
			getSupplierUrl(`/request`),
			{ params: data }
		);
		return response;
	},

	async getRequestById(id: number) {
		const response = await axiosInstance.post<TSupplier.RequestDataDetail>(
			getSupplierUrl(`/requests/${id}`)
		);
		return response;
	},

	// Старый код
	//////////	//////////	//////////	//////////	//////////	//////////	//////////

	async getDistributors(data?: TSupplier.Filter) {
		const response = await axiosInstance.get<TSupplier.ChainStoreList>(
			getSupplierUrl(`/distributors/`),
			{ params: data }
		);
		return response;
	},

	async getManufacturers(data?: TSupplier.Filter) {
		const countries = data?.country_ids?.join(',');

		const response = await axiosInstance.get<TSupplier.ChainStoreList>(
			getSupplierUrl(`/manufacturers/`),
			{ params: { countries } }
		);
		return response;
	},

	async getManufacturerById(id: number) {
		const response = await axiosInstance.get<TSupplier.ManufacturerDetail>(
			getSupplierUrl(`/manufacturers/${id}/`)
		);
		return response;
	},

	async getCheckReview(data: TSupplier.ReviewCheck) {
		const response = await axiosInstance.get<TSupplier.ReviewsList>(
			getSupplierUrl(`/review/check/`),
			{ params: data }
		);
		return response;
	},

	async addReviewAnswer(id: number, data: TSupplier.ReviewsData) {
		const response = await axiosInstance.patch<TSupplier.ReviewUpdate>(
			getSupplierUrl(`/review/${id}/rep_response/`),
			data
		);
		return response;
	}
};

export default SupplierService;
