import ClosedDropdown from 'entities/profile/profile-dropdown';
import { useGetProject, useProjectsActions } from 'features/projects/api';
import { FC, useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import { useAlertMessage } from 'shared/hooks';

interface IProps {
	isOpen: boolean;
	onClose: () => void;
}

export const CreateProjectPopup: FC<IProps> = ({ isOpen, onClose }) => {
	const { data } = useGetProject();
	const { addAlertMessage } = useAlertMessage();
	const { createProject } = useProjectsActions();
	const [projectsList, setProjectsList] = useState<any[]>();
	const [projectName, setProjectName] = useState('');
	const [selectedProject, setSelectedProject] = useState();

	const handlePopupClose = () => {
		onClose();
	};

	const changeProjectName = (item: string) => {
		setProjectName(item);
	};

	useEffect(() => {
		if (!data) return;
		const parsedData = data?.results?.map(i => {
			return {
				id: i.id,
				label: i.name
			};
		});
		setProjectsList(parsedData);
	}, [data]);

	const CreateProject = async () => {
		try {
			const dataProject = {
				name: projectName,
				...(selectedProject
					? {
							parent_id: selectedProject
					  }
					: {})
			};
			await createProject.mutateAsync(dataProject);
			window.location.reload();
		} catch (error: any) {
			addAlertMessage(error, 'error');
		}
	};

	return (
		<Popup
			className='createproject-popup-base'
			open={isOpen}
			closeOnDocumentClick
			onClose={handlePopupClose}
			contentStyle={{
				borderRadius: '15px',
				background: '#fff'
			}}
			overlayStyle={{
				background: 'rgba(0, 0, 0, 0.5)'
			}}
		>
			<div>
				<div className='requisites-popup-header'>
					<h1>
						Создать проект
						<span className='note'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='18'
								height='18'
								viewBox='0 0 18 18'
								fill='none'
							>
								<path
									d='M7.59375 6.65625C7.59375 5.8796 8.22335 5.25 9 5.25C9.77665 5.25 10.4062 5.8796 10.4062 6.65625C10.4062 7.17183 10.1288 7.62261 9.71502 7.86741C9.35853 8.07832 9 8.39829 9 8.8125V9.75'
									stroke='#777777'
									strokeLinecap='round'
								/>
								<circle cx='9' cy='12' r='0.75' fill='#777777' />
								<path
									d='M5.25 2.50337C6.35315 1.86523 7.63392 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 7.63392 1.86523 6.35315 2.50337 5.25'
									stroke='#777777'
									strokeLinecap='round'
								/>
							</svg>
						</span>
						<span className='note-text'>
							Выберите проект или создайте новый, что бы добавить товар в список
						</span>
					</h1>

					<button onClick={handlePopupClose}>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='32'
							height='32'
							viewBox='0 0 32 32'
							fill='none'
						>
							<path
								d='M19.75 12.25L12.25 19.75M12.25 12.25L19.7499 19.75'
								stroke='#8A7DE7'
								strokeWidth='1.5'
								strokeLinecap='round'
							/>
							<path
								d='M31 16C31 23.0711 31 26.6066 28.8033 28.8033C26.6066 31 23.0711 31 16 31C8.92893 31 5.3934 31 3.1967 28.8033C1 26.6066 1 23.0711 1 16C1 8.92893 1 5.3934 3.1967 3.1967C5.3934 1 8.92893 1 16 1C23.0711 1 26.6066 1 28.8033 3.1967C30.2639 4.65731 30.7533 6.70983 30.9173 10'
								stroke='#8A7DE7'
								strokeWidth='1.5'
								strokeLinecap='round'
							/>
						</svg>
					</button>
				</div>
				<div className='projectchange-popup-form'>
					<input
						value={projectName}
						onChange={e => changeProjectName(e.target.value)}
						type='text'
						placeholder='Назовите папку'
						required
					/>
					<ClosedDropdown
						data={projectsList}
						handleSelectedItem={setSelectedProject}
					/>
				</div>
				<button onClick={CreateProject} className='projectchange-popup-button'>
					Создать
				</button>
			</div>
		</Popup>
	);
};
