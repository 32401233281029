import { toFormData } from 'axios';
import { axiosInstance } from 'shared/api';
import { getPartnerUrl, getProjectUrl } from 'shared/api/config';
import { TPartner } from './types/partner.types';

const PartnerService = {
	async becomePartner(data: TPartner.Create) {
		const response = await axiosInstance.post<TPartner.PartnerResponse>(
			getPartnerUrl(``),
			data
		);
		return response;
	},
	async getInfo() {
		const response = await axiosInstance.get<TPartner.PartnerResponse>(
			getPartnerUrl(`/my`)
		);
		return response;
	},

	async updatePartner(data: TPartner.Update) {
		const response = await axiosInstance.patch<TPartner.PartnerResponse>(
			getPartnerUrl(`/my`),
			data
		);
		return response;
	},

	async addLogo(logo: File) {
		const response = await axiosInstance.patch<TPartner.PartnerResponse>(
			getPartnerUrl(`/add/logo`),
			toFormData({ logo })
		);
		return response;
	},

	//TODO устаревшие данные

	async addFile(data: TPartner.PartnerFile) {
		const response = await axiosInstance.post<TPartner.PartnerFileData>(
			getPartnerUrl(`/add-file`),
			toFormData(data)
		);
		return response;
	},

	async getPartnersList(data?: TPartner.PartnerFilter) {
		const currentData = Object.entries(data || {}).reduce(
			(acc, [key, value]) => {
				if (!value) return acc;
				if (Array.isArray(value)) return { ...acc, [key]: value.join(',') };
				return { ...acc, [key]: value };
			},
			{}
		);

		const response = await axiosInstance.get<TPartner.PartnerFileData>(
			getPartnerUrl(`/all`),
			{ params: currentData }
		);
		return response;
	},

	async getRequestPartners() {
		const response = await axiosInstance.get<TPartner.RequestToPartner[]>(
			getPartnerUrl(`/requests`)
		);
		return response;
	},

	async sendRequestPartners(data: TPartner.RequestToPartnerParams) {
		const response = await axiosInstance.post<string>(
			getPartnerUrl(`/requests`),
			toFormData(data)
		);
		return response;
	},

	async getInfoRequest(id: number) {
		const response = await axiosInstance.get<string>(
			getPartnerUrl(`/requests/${id}`)
		);
		return response;
	},

	async addCommentRequest(data: TPartner.RequestToPartnerComment) {
		const response = await axiosInstance.post<TPartner.RequestToPartner>(
			getPartnerUrl(`/requests/comment`),
			toFormData(data)
		);
		return response;
	},

	async deleteCommentRequest(id: number) {
		const response = await axiosInstance.delete<string>(
			getPartnerUrl(`/requests/comment/${id}`)
		);
		return response;
	},

	async updateInfoRequest(id: number, data: TPartner.RequestToPartnerUpdate) {
		const response = await axiosInstance.patch<TPartner.RequestToPartnerUpdate>(
			getPartnerUrl(`/requests/${id}`),
			toFormData(data)
		);
		return response;
	},

	async deleteInfoRequest(id: number) {
		const response = await axiosInstance.delete<string>(
			getPartnerUrl(`/requests/${id}`)
		);
		return response;
	},

	async pickPartner(products?: number[]) {
		const productsIds = products?.join(',');

		const response = await axiosInstance.get<string>(
			getProjectUrl(`/pick/partners`),
			{ params: { products: productsIds } }
		);
		return response;
	}
};

export default PartnerService;
