import { useMutation, useQueryClient } from '@tanstack/react-query';
import ChatService from 'shared/services/chat.service';
import { TChat } from 'shared/services/types/';

export const useChatActions = () => {
	const queryClient = useQueryClient();

	const onSendFile = useMutation({
		mutationFn: (data: TChat.AttachFileMessage) =>
			ChatService.getAttachFile(data),
		mutationKey: ['getAttachFile'],
		retry: 0,
		onSuccess: () => {
			// queryClient.invalidateQueries({ queryKey: [`get price source`] });
		}
	});

	return {
		onSendFile
	};
};
