import { useInfiniteQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import ProductService from 'shared/services/product.service';
import { TProduct } from 'shared/services/types/products.types';

export const useProductList = (
	filter?: TProduct.ProductFilter,
	page_size: number = 50
) => {
	const [dataList, setDataList] = useState<TProduct.ProductResponse | null>(
		null
	);

	const productList = useInfiniteQuery({
		queryKey: ['get product list', filter],
		initialPageParam: 1,
		enabled: !!filter?.search,
		queryFn: ({ pageParam = 1 }) => {
			const order_by = filter?.order_by;
			return ProductService.getList({
				...filter!,
				page: pageParam,
				page_size: page_size
			});
		},
		getNextPageParam: (lastPage, allPages) => allPages.length + 1,
		retry: 0,
		select: data => {
			const res = data.pages.map(item => ({
				...item.data
			}));

			return { ...data, pages: res };
		}
	});

	useEffect(() => {
		const { data, isPending } = productList;

		const result = data?.pages.reduce((acc, page) => {
			return {
				...page,
				results: [...(acc?.results || []), ...(page?.results || [])]
			};
		}, {} as any);

		if (!isPending) setDataList(result);
	}, [productList.data?.pages, productList.isPending]);

	return {
		productList: {
			...productList,
			data: {
				...productList.data,
				...dataList
			}
		}
	};
};
