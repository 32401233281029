import { FC, useState } from 'react';
import Select from 'react-select';
import { TProject } from 'shared/services/types';

interface IProps {
	projectData?: TProject.DataMini[];
	productToProject: React.Dispatch<React.SetStateAction<number | null>>;
}

export const FoldersProjectSelect: FC<IProps> = ({
	projectData,
	productToProject
}) => {
	const [selectedOption, setSelectedOption] = useState(null);
	const options = projectData
		? projectData.map(project => ({
				value: project.id,
				label: project.name
		  }))
		: [];

	const handleChange = (selectedOption: any) => {
		setSelectedOption(selectedOption);
		productToProject(selectedOption?.value || null);
	};

	const placeholderText =
		projectData && !!projectData.length
			? 'Выберите проект'
			: 'Нет созданной папки';

	const CustomIndicator = (props: any) => {
		return (
			<div className='settings-select__arrow' {...props.innerProps}>
				<svg
					width='14'
					height='8'
					viewBox='0 0 14 8'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						d='M12.8327 1.5L6.99935 6.5L1.16602 1.5'
						stroke='#333333'
						strokeWidth='1.5'
						strokeLinecap='round'
						strokeLinejoin='round'
					/>
				</svg>
			</div>
		);
	};

	return (
		<>
			<Select
				value={selectedOption}
				onChange={handleChange}
				options={options}
				placeholder={placeholderText}
				classNamePrefix={'settings-select'}
				isSearchable={false}
				components={{ DropdownIndicator: CustomIndicator }}
			/>
		</>
	);
};
