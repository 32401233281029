import { addDays, formatISO } from 'date-fns';
import { SourceItem } from 'entities/profile/source-item';
import EditSource from 'entities/statistics/edit-source';
import FreezeWarning from 'entities/statistics/freeze-warning';
import RemoveSource from 'entities/statistics/remove-source';
import { usePriceSource } from 'features/profile/api/usePriceSource';
import StatisticsWarning from 'features/statistics/ui/statistics-warning';
import { useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useAlertMessage } from 'shared/hooks';
import { TPrice } from 'shared/services/types';
import { FormWrapper } from 'shared/ui/form/form-wrapper';
import { Input } from 'shared/ui/form/input';

const DataSource = () => {
	const [sourceData, setSourceData] = useState<TPrice.Source>();
	const { addAlertMessage } = useAlertMessage();
	const methods = useForm();

	const { getSource, addSource, updateSource, deletePriceSource } =
		usePriceSource(sourceData?.id);

	const [isModalVisible, setModalVisible] = useState(false);
	const [isModalVisibleComplaint, setModalVisibleComplaint] = useState(false);
	const [isModalVisibleFreezeWarning, setModalVisibleFreezeWarning] =
		useState(false);
	const [isModalEdit, setModalEdit] = useState(false);

	const openModal = (data: TPrice.Source) => {
		setSourceData(data);

		setModalVisible(true);
	};

	const closeModal = () => {
		setModalVisible(false);
	};

	const openModalComplaint = (data: TPrice.Source) => {
		setSourceData(data);

		setModalVisibleComplaint(true);
	};

	const closeModalComplaint = () => {
		setModalVisibleComplaint(false);
	};

	const closeModalFreezeWarning = () => {
		setModalVisibleFreezeWarning(false);
	};

	const openModalEdit = (data: TPrice.Source) => {
		setSourceData(data);
		setModalEdit(true);
	};

	const closeModalEdit = () => {
		setModalEdit(false);
	};

	const onDeleteSource = async () => {
		try {
			await deletePriceSource.mutateAsync();
			closeModal();
		} catch (error: any) {
			addAlertMessage(error, 'error');
		}
	};

	const onEditSource = async (text: string) => {
		try {
			await updateSource.mutateAsync({ url: text });
			closeModalEdit();
		} catch (error: any) {
			addAlertMessage(error, 'error');
		}
	};

	const onFreezeSource = async () => {
		const today = new Date();
		const twoDaysFromNow = addDays(today, 2);

		const frozen = formatISO(twoDaysFromNow);

		try {
			await updateSource.mutateAsync({ url: sourceData?.url, frozen });
			closeModalEdit();
			closeModal();
		} catch (error: any) {
			addAlertMessage(error, 'error');
		}
	};

	const onUnfreezeSource = async () => {
		try {
			await updateSource.mutateAsync({ url: sourceData?.url, frozen: null });
			closeModalComplaint();
		} catch (error: any) {
			addAlertMessage(error, 'error');
		}
	};

	const addNewSource = (data: FieldValues) => {
		addSource.mutateAsync({ url: data.url }).catch(e => {
			addAlertMessage(e, 'error');
		});
	};

	return (
		<div className='data-source'>
			<div className='data-source__add'>
				<p>Добавьте новый (до 3-х бесплатно)</p>
				<FormWrapper methods={methods} onSubmit={addNewSource}>
					<div className='inputs-row'>
						<Input
							control={methods.control}
							name='url'
							label='Укажите название источника'
							placeholder='Укажите название источника'
							required
							error={methods.formState.errors}
							rules={{ required: 'Обязательное поле для заполнения' }}
						/>

						<button className='btn mobi-full' type='submit'>
							Добавить
							<svg
								width='18'
								height='18'
								viewBox='0 0 18 18'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<g clipPath='url(#clip0_369_8328)'>
									<path
										d='M11.25 9.00002L9 9.00002M9 9.00002L6.75 9.00002M9 9.00002L9 6.75M9 9.00002L9 11.25'
										stroke='white'
										strokeWidth='1.3'
										strokeLinecap='round'
									/>
									<path
										d='M5.25 2.50337C6.35315 1.86523 7.63392 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 7.63392 1.86523 6.35315 2.50337 5.25'
										stroke='white'
										strokeWidth='1.3'
										strokeLinecap='round'
									/>
								</g>
								<defs>
									<clipPath id='clip0_369_8328'>
										<rect width='18' height='18' fill='white' />
									</clipPath>
								</defs>
							</svg>
						</button>
					</div>
				</FormWrapper>
			</div>
			<div className='data-source__list'>
				{getSource?.data?.map(item => (
					<SourceItem
						key={item.id}
						item={item}
						onClickEdit={openModalEdit}
						onOpenModal={openModal}
						onFreezeModal={openModalComplaint}
					/>
				))}
			</div>
			<StatisticsWarning
				isOpen={isModalVisible}
				onClose={closeModal}
				onDelete={onFreezeSource}
			/>
			<RemoveSource
				isOpen={isModalVisibleComplaint}
				onClose={closeModalComplaint}
				onRemove={onDeleteSource}
				onUnfreeze={onUnfreezeSource}
				sourceData={sourceData}
			/>
			<FreezeWarning
				isOpen={isModalVisibleFreezeWarning}
				onClose={closeModalFreezeWarning}
			/>
			<EditSource
				sourceData={sourceData}
				isOpen={isModalEdit}
				onClose={closeModalEdit}
				onEdit={onEditSource}
			/>
		</div>
	);
};

export default DataSource;
