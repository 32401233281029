import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ReviewService } from 'shared/services';
import { TReview } from 'shared/services/types';

export const useReviewsActions = () => {
	const queryClient = useQueryClient();

	const onCreateComplaint = useMutation({
		mutationFn: (data: TReview.CreateComplaint) =>
			ReviewService.createComplaint(data),
		mutationKey: ['create complaint'],
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: [`get reviews list`] });
		}
	});

	return { onCreateComplaint };
};
