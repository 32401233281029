import { useHelpAction } from 'features/help/api/useHelp';
import { useEffect, useState } from 'react';
import './ProfileHelp.scss';
import { Link } from 'react-router-dom';

const ProfileHelp = () => {
	const { getFqa } = useHelpAction();
	const [open, setOpen] = useState<any[]>([]);
	const [faq, setFaq] = useState<{ question: string; answer: string }[]>();

	const transformAnswerToHTML = () => {
		const data = getFqa?.data?.map(item => {
			const answer = item.answer.includes('\r\n')
				? item.answer.split('\r\n')
				: item.answer.split('\\n');

			return {
				question: item.question,
				answer: answer
					.map((v, i) => {
						if (!i) return `<p>${v}</p>`;
						return `<li>${v}</li>`;
					})
					.join(',')
					.replace(/,<li>/g, '<li>')
			};
		});
		return data;
	};

	useEffect(() => {
		getFqa.refetch();
	}, []);

	useEffect(() => {
		const data = transformAnswerToHTML();
		if (getFqa.data) setFaq(data);
	}, [getFqa.data]);

	const switchOpen = (index: number) => {
		setOpen(prevStates => ({
			...prevStates,
			[index]: !prevStates[index]
		}));
	};

	return (
		<div className='help'>
			<div className='help-header'>
				<h1>Часто задаваемые вопросы</h1>
				<p>
					Мы собрали часто задаваемые вопросы. Если они не помогут решить ваш
					вопрос, <br /> то обратитесь в поддержку в разделе <Link to={'/account/chat'}>“Чат поддержки”
					</Link>.
				</p>
			</div>
			{faq?.map((item, index) => (
				<div
					key={index}
					className={`help-dropdown ${open[index] ? 'active' : ''}`}
				>
					<div
						onClick={() => switchOpen(index)}
						className={`help-dropdown-header ${open[index] ? 'active' : ''}`}
					>
						<h3>{item.question}</h3>
						<i>
							<svg
								width='34'
								height='34'
								viewBox='0 0 34 34'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M21.25 14.875L17 19.125L12.75 14.875'
									stroke='#333333'
									strokeWidth='1.5'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
								<path
									d='M31.1654 17.0002C31.1654 23.6784 31.1654 27.0175 29.0907 29.0922C27.016 31.1668 23.6769 31.1668 16.9987 31.1668C10.3205 31.1668 6.98135 31.1668 4.90669 29.0922C2.83203 27.0175 2.83203 23.6784 2.83203 17.0002C2.83203 10.3219 2.83203 6.98282 4.90669 4.90816C6.98135 2.8335 10.3205 2.8335 16.9987 2.8335C23.6769 2.8335 27.016 2.8335 29.0907 4.90816C30.4702 6.28762 30.9324 8.22611 31.0873 11.3335'
									stroke='#333333'
									strokeLinecap='round'
								/>
							</svg>
						</i>
					</div>
					<div
						className={`help-dropdown-content ${open[index] ? 'open' : ''}`}
						dangerouslySetInnerHTML={{ __html: item.answer }}
					></div>
				</div>
			))}
		</div>
	);
};

export default ProfileHelp;
