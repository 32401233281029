import { useUserActionById } from 'features/profile/api';
import { FC } from 'react';
import Popup from 'reactjs-popup';
import { useAlertMessage } from 'shared/hooks';

interface IProps {
	id: number;
	isOpen: boolean;
	onClose: () => void;
}

export const DeleteUserPopup: FC<IProps> = ({ isOpen, onClose, id }) => {
	const { deleteCompanyUser } = useUserActionById();
	const { addAlertMessage } = useAlertMessage();

	const changeEmployee = async () => {
		try {
			await deleteCompanyUser.mutateAsync(id);
			onClose();
		} catch (error: any) {
			addAlertMessage(error, 'error');
		}
	};

	return (
		<Popup
			className='delete-popup-base'
			open={isOpen}
			closeOnDocumentClick
			onClose={onClose}
		>
			<div>
				<div className='delete-popup-header'>
					<h3>Уверены, что хотите удалить сотрудника?</h3>
					<button type='button' onClick={onClose}>
						<svg
							width='32'
							height='32'
							viewBox='0 0 32 32'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								d='M19.75 12.25L12.25 19.75M12.25 12.25L19.7499 19.75'
								stroke='#8A7DE7'
								strokeWidth='1.5'
								strokeLinecap='round'
							/>
							<path
								d='M31 16C31 23.0711 31 26.6066 28.8033 28.8033C26.6066 31 23.0711 31 16 31C8.92893 31 5.3934 31 3.1967 28.8033C1 26.6066 1 23.0711 1 16C1 8.92893 1 5.3934 3.1967 3.1967C5.3934 1 8.92893 1 16 1C23.0711 1 26.6066 1 28.8033 3.1967C30.2639 4.65731 30.7533 6.70983 30.9173 10'
								stroke='#8A7DE7'
								stroke-width='1.5'
								stroke-linecap='round'
							/>
						</svg>
					</button>
				</div>
				<div>
					<form
						onSubmit={e => {
							e.preventDefault();
							changeEmployee();
						}}
						className='delete-form'
					>
						<button className='btn' type='submit'>
							Да
						</button>
						<button className='btn' type='button' onClick={onClose}>
							Нет
						</button>
					</form>
				</div>
			</div>
		</Popup>
	);
};
