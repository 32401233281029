import { axiosInstance } from 'shared/api';
import { getUserUrl } from 'shared/api/config';
import { TUser } from './types/user.types';

const UserService = {
	async getUser(id: number) {
		const response = await axiosInstance.get<TUser.Data>(
			getUserUrl(`/profile/${id}`)
		);
		return response;
	},

	async updateUser(id: number, data: TUser.Update) {
		const response = await axiosInstance.patch<TUser.Data>(
			getUserUrl(`/profile/${id}`),
			data
		);
		return response;
	},

	async telegramConfirm(id: number, data: TUser.TelegramConfirm) {
		const response = await axiosInstance.patch<TUser.Data>(
			getUserUrl(`/user/profile/${id}/link/telegram/confirm`),
			data
		);
		return response;
	},

	async deleteUser(id: number) {
		const response = await axiosInstance.delete<string>(
			getUserUrl(`/profile/${id}`)
		);
		return response;
	}
};

export default UserService;
