import { FirstNumber, SecondNumber, ThirdNumber } from 'assets/Numbers';
import { BlurOverlay } from 'entities/blur-overlay';
import { PartnerPermissionModal } from 'entities/partners/permission-modal';
import VerificationStatus from 'entities/partners/verification-status';
import { usePartnersActions } from 'features/partners/api/usePartnersActions';
import { useGetUser } from 'features/profile/api/useGetUser';
import { useGetTariff } from 'features/tariff/api/useGetTariff';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useAlertMessage } from 'shared/hooks';
import { TPartner } from 'shared/services/types';
import Dropdown from 'shared/ui/dropdown';
import { FormWrapper } from 'shared/ui/form/form-wrapper';
import { tokenParse } from 'shared/utils';
import './ProfilePartners.scss';
import ProfilePartnersCapacities from './capacities';
import ProfilePartnersSurvey from './survey';
import ProfilePartnersTerms from './terms';

const partnerListAccess = [
	`Партнёр`,
	`Корпоративный партнёр`,
	`Прайс-лист с меткой "Под заказ"`,
	`Размещение прайс-листа`,
	'Стать партнёром на год'
];

const ProfilePartners = () => {
	const methods = useForm({
		defaultValues: {
			settlements: [{ number: '', currency_id: '' }],
			workDays: [{ day: '', time: [] }],
			delivery_services: []
		}
	});

	const { addLogo, getPartnerInfo, onCreatePartner } = usePartnersActions();
	const { userData } = useGetUser();
	const { tariffName, isPending } = useGetTariff();
	const { is_boss } = tokenParse();

	const { addAlertMessage } = useAlertMessage();
	const [showOverlay, setShowOverlay] = useState<string | boolean>(false);

	const [isOpen, setIsOpen] = useState(false);

	const onCloseModal = () => {
		setIsOpen(false);
	};

	const handlePartnerData = async (data: FieldValues) => {
		const value = data;
		const distributors = value?.distributors?.map((item: any) => item.value);
		const settlements = value.settlements.map((item: any) => ({
			...item,
			currency_id: item.currency.label
		}));
		const vendors = value?.vendors?.map((item: any) => ({
			vendor: item.vendor,
			status: item.status
		}));

		const logo = value.logo;

		const workdays = value?.workDays?.map((item: any) => ({
			day: item.day.value,
			time: item.time.join(' - ')
		}));

		const dataObject: TPartner.Create = {
			supplier: {
				description: value?.description,
				phone_number: value?.phone_number,
				site_url: value?.site_url,
				nds: value?.nds?.value,
				currency_id: userData!.currency.id,
				country_id: userData!.country.id,
				time_zone: moment.tz.guess(),
				product_delivery: value?.product_delivery,
				product_documents: value.product_documents.value,
				answer_time: value?.answer_time,
				email: value?.email
			},

			vendors: vendors,
			suppliers_ids: distributors,
			surcharge: value?.surcharge,

			settlements: settlements,
			work_days: workdays,
			service_center: value?.service_centers
		};

		try {
			await onCreatePartner.mutateAsync(dataObject as TPartner.Create);
			addAlertMessage('Заявка на становление партнером отправлена', 'info');
			if (logo) {
				await addLogo.mutateAsync(logo);
			}
			getPartnerInfo.refetch();
		} catch (error: any) {
			addAlertMessage(error, 'error');
			console.log(error);
		}
	};

	useEffect(() => {
		const { error, isFetching } = getPartnerInfo;

		if (!isFetching && error) {
			//@ts-ignore
			const errorMessage = error?.response?.data?.detail;
			setShowOverlay(errorMessage);
		}
	}, [getPartnerInfo.data, getPartnerInfo.error]);

	const checkSub = () => {
		return partnerListAccess.includes(tariffName);
	};

	const isCoorpTariff = checkSub();

	useEffect(() => {
		if (!isCoorpTariff && !isPending) {
			setIsOpen(true);
			setShowOverlay(true);
		}
		if (!is_boss && getPartnerInfo.data) {
			setShowOverlay('Вы не руководитель компании');
		}
	}, [isCoorpTariff, isPending, is_boss, getPartnerInfo.data]);

	return (
		<>
			{getPartnerInfo?.data?.partner?.status && (
				<VerificationStatus status={getPartnerInfo?.data?.partner?.status} />
			)}

			<div className='profile-container partners-container'>
				<div className='warning-text'>
					<p>
						Пожалуйста, вводите достоверные данные, которые, при необходимости,
						сможете подтвердить документально
					</p>
					<p>
						<strong style={{ color: 'red' }}>*</strong> - обязательное поле для
						заполнения
					</p>
				</div>
				<FormWrapper
					methods={methods}
					onSubmit={handlePartnerData}
					className='terms-form'
					noValidateForm
				>
					<Dropdown
						title='Анкета'
						content={
							<ProfilePartnersSurvey
								methods={methods}
								defaultData={getPartnerInfo?.data}
							/>
						}
						number={<FirstNumber />}
					/>
					<Dropdown
						title='Расскажите об условиях'
						content={
							<ProfilePartnersTerms
								methods={methods}
								defaultData={getPartnerInfo?.data}
							/>
						}
						number={<SecondNumber />}
					/>
					<Dropdown
						title='Ваши компетенции'
						content={
							<ProfilePartnersCapacities
								methods={methods}
								defaultData={getPartnerInfo?.data}
							/>
						}
						number={<ThirdNumber />}
					/>

					{/* <Dropdown
						title='Документы'
						content={
							<ProfilePartnersDocuments
								onDataChange={handleCapacitiesData}
								methods={methods}
								defaultData={partnerData}
							/>
						}
						number={<FourNumber />}
					/> */}

					{!getPartnerInfo?.data && (
						<button type='submit' className='btn partnerrequest-button'>
							Отправить запрос
						</button>
					)}
				</FormWrapper>
				<BlurOverlay error={'dev'} isWindow />
				{/* {!!showOverlay && (!checkSub() || !is_boss) && (
					<div className='profile-overlay'>
						<div className='profile-overlay-content'>
							<p>
								{typeof showOverlay === 'string' ? (
									showOverlay
								) : (
									<>
										Для того чтобы стать партнером смените тариф на подходящий в
										разделе
										<Link to='/account/tariffs'> “Тарифы и оплата”</Link>
									</>
								)}
							</p>
						</div>
					</div>
				)} */}

				<PartnerPermissionModal
					isOpen={isOpen}
					onClose={onCloseModal}
					title='Партнером можно стать на следующих тарифах:'
					data={partnerListAccess}
				/>
			</div>
		</>
	);
};

export default ProfilePartners;
