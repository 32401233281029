import { PasswordRecoveryConfirmModal } from 'features/profile/ui/password-recovery-confirm';
import PasswordRecoveryModal from 'features/profile/ui/password-recovery-modal';
import { useEffect, useState } from 'react';
import './ProfilePassChange.scss';

import { useAuthActions } from 'features/auth/api/useAuthActions';
import { FieldValues, useForm } from 'react-hook-form';
import { useAlertMessage, useQueryParams } from 'shared/hooks';
import { TAuth } from 'shared/services/types';
import { FormWrapper } from 'shared/ui/form/form-wrapper';
import { Input } from 'shared/ui/form/input';

const ProfilePassChange = () => {
	const methods = useForm();
	const { token } = useQueryParams();
	const [isOpenPassword, setIsOpenPassword] = useState(false);
	const { addAlertMessage } = useAlertMessage();
	const { passwordChange } = useAuthActions();

	useEffect(() => {
		if (token) {
			setIsOpenPassword(true);
		}
	}, [token]);

	const passwordChangeSubmit = async (formData: FieldValues) => {
		const data = formData as TAuth.PasswordChange;
		try {
			await passwordChange.mutateAsync(data);
			addAlertMessage('Пароль успешно изменен', 'success');
		} catch (error: any) {
			addAlertMessage(error, 'error');
		}
	};

	const [isModalVisible, setModalVisible] = useState(false);

	const openModal = () => {
		setModalVisible(true);
	};

	const closeModal = () => {
		setModalVisible(false);
	};

	return (
		<div className='pass-container'>
			<h1 className='passchange-header'>Сменить пароль</h1>
			<p className='passchange-text'>
				Пароль должен быть не короче 6 знаков и состоять из символов латинского
				алфавита.
			</p>
			<FormWrapper
				methods={methods}
				className='passchange-form'
				onSubmit={passwordChangeSubmit}
			>
				<Input
					placeholder='Текущий пароль'
					control={methods.control}
					label='Текущий пароль'
					isPassword
					required
					name='old_password'
					error={methods.formState.errors}
					rules={{ required: 'Обязательное поле для заполнения' }}
				/>
				<Input
					placeholder='Новый пароль'
					control={methods.control}
					label='Новый пароль'
					isPassword
					required
					name='new_password1'
					error={methods.formState.errors}
					rules={{ required: 'Обязательное поле для заполнения' }}
				/>

				<Input
					placeholder='Повторите новый пароль'
					control={methods.control}
					label='Повторите новый пароль'
					required
					isPassword
					name='new_password2'
					error={methods.formState.errors}
					rules={{
						required: 'Обязательное поле для заполнения',
						validate: value => {
							const { new_password1 } = methods.getValues();
							return value === new_password1 || 'Пароли не совпадают';
						}
					}}
				/>

				<div className='block-save'>
					<button
						className='btn'
						type='submit'
						disabled={passwordChange.isPending}
					>
						Сохранить
					</button>
					<button
						type='button'
						className='reset-password-modal'
						onClick={openModal}
					>
						Забыли текущий пароль?
					</button>
				</div>
			</FormWrapper>

			<PasswordRecoveryModal isOpen={isModalVisible} onClose={closeModal} />
			<PasswordRecoveryConfirmModal
				isOpen={isOpenPassword}
				onClose={() => {
					setIsOpenPassword(false);
				}}
			/>
		</div>
	);
};

export default ProfilePassChange;
