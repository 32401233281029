import React, { useContext } from 'react'
import MainLogo from '../../assets/MainLogo'
import './Footer.scss'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import CommonContext from '../../services/AuthContext'

export const Footer = () => {
	const location = useLocation()
	const currentLocation = location.pathname
	const navigate = useNavigate()
	const navigateToRegister = () => {
		navigate('/register/')
	}
	const navigateToLogin = () => {
		navigate('/login')
	}
	const { authState } = useContext(CommonContext)

	const currentDate = new Date().getFullYear()

	return (
		<div className='footer'>
			<div className='container'>
				<div className='footer__top'>
					<div className='footer__menu'>
						{/* <Link to='/about'>О проекте</Link> */}
						<Link to={'/'}>Поиск товаров</Link>
						<Link to='/suppliers'>Каталог поставщиков</Link>
						<Link to={authState ? '/account/partner' : '/login'}>
							Стать партнером
						</Link>
						<Link to='/buyers'>Покупателям</Link>
						<Link to='/for-suppliers'>Поставщикам</Link>
						<a
							target='_blank˝'
							href={`https://t.me/Kira_TovaryOptomDlyaProdazhi_Bot`}
						>
							Телеграм Бот{' '}
						</a>
					</div>
					<div style={{ width: '200px' }}>
						<div className='footer__logo'>
							<MainLogo />
							<p>Собрал поставщиков со всего мира</p>
						</div>
					</div>
				</div>
			</div>

			<div className='footer-bottom'>
				<div className={'container'}>
					<div style={{ display: 'flex' }}>
						<div className='footer-bottom__row'>
							<div className='bgsvg'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='1429'
									height='58'
									viewBox='0 0 1429 58'
									fill='none'
									preserveAspectRatio='none'
								>
									<path d='M0 0H1389.31L1429 58H0V0Z' fill='#EBF1F8' />
								</svg>
							</div>
							<p  className='footer-bottom-p'>
								© {currentDate}{' '}
								<span>KИRA Сервис по поиску товаров и поставщиков.</span>
								<span> Все права защищены</span>
							</p>
							<p>
								<Link to={'/terms_of_use'}>Пользовательское соглашение</Link>
							</p>
							<p>
								<Link to={'/privacy_policy'}>Политика конфиденциальности</Link>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
