import LoginPage from 'pages/login';
import React, { PropsWithChildren } from 'react';

interface IProps extends PropsWithChildren {
	noAuth?: boolean;
}

export const ProtectedRoute: React.FC<IProps> = ({ children, noAuth }) => {
	const token = localStorage.getItem('kira_token');

	if (!token && !noAuth) return <LoginPage />;
	return <>{children}</>;
};
