import { useMutation, useQueryClient } from '@tanstack/react-query';
import CompanyService from 'shared/services/company.service';
import { TCompany } from 'shared/services/types/company.types';

export const useCompanyActions = () => {
	const queryClient = useQueryClient();

	const createCompany = useMutation({
		mutationFn: (data: TCompany.Data) => CompanyService.createCompany(data),
		mutationKey: ['create company'],
		onSuccess: ({ data }) => {
			queryClient.invalidateQueries({ queryKey: [`get company`] });
			return data;
		}
	});

	const updateCompany = useMutation({
		mutationFn: (data: TCompany.DataUpdate) =>
			CompanyService.updatePartCompany(data),
		mutationKey: ['update company'],
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: [`get company`] });
		}
	});

	const addIncreaseCompany = useMutation({
		mutationFn: (data: TCompany.IncreaseCompany) =>
			CompanyService.getIncreaseCompany(data),
		mutationKey: ['increase company']
	});

	const sendInviteCompany = useMutation({
		mutationFn: (data: TCompany.InviteCompany) =>
			CompanyService.sendInviteToCompany(data),
		mutationKey: ['invite company']
	});

	return {
		createCompany,
		updateCompany,
		addIncreaseCompany,
		sendInviteCompany
	};
};
