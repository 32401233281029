import { useAuthActions } from 'features/auth/api';
import { FieldValues, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { axiosInstance } from 'shared/api';
import { useAlertMessage } from 'shared/hooks';
import { TAuth } from 'shared/services/types/auth.types';
import { FormWrapper } from 'shared/ui/form/form-wrapper';
import { Input } from 'shared/ui/form/input';
import '../AuthForm.scss';
import './RegisterForm.scss';
import { useHelpRegister } from './useHelpRegister';
import { REGEX_VERIFY } from 'shared/regex';

const RegisterForm = () => {
	const { email, first_nameNew, last_nameNew, tokenNew, patronymicNew } =
		useHelpRegister();

	const methods = useForm({ mode: 'onBlur' });

	const { addAlertMessage } = useAlertMessage();
	const navigate = useNavigate();

	const { onRegister, onLogin } = useAuthActions();

	const regSubmit = async (formData: FieldValues) => {
		const registerData = {
			...formData
		} as TAuth.Register;

		try {
			await onRegister.mutateAsync({
				...registerData,
				verify_url: window.location.origin + '/account/profile'
			});
			navigate('/');
			await onLogin.mutateAsync({
				username: registerData.email,
				password: registerData.password1
			});
			navigate('/account/profile');
		} catch (error: any) {
			addAlertMessage(error, 'error');
		}
	};

	const regEmployee = async (formData: FieldValues) => {
		const data = {
			last_name: last_nameNew,
			first_name: first_nameNew,
			patronymic: patronymicNew,
			email: email,
			verify_url: window.location.origin + '/account/profile',
			...formData
		};

		const loginData = {
			//@ts-ignore
			username: formData.email || email,
			password: formData.password1
		};

		try {
			await axiosInstance.post(`/company/invite/confirm`, data, {
				params: { token: tokenNew }
			});
			await onLogin.mutateAsync(loginData);
			navigate('/account/profile');
		} catch (error: any) {
			addAlertMessage(error, 'error');
		}
	};

	return (
		<div className='auth-container'>
			<div className='auth-block'>
				<div className='auth-block__header'>
					<h1>Регистрация</h1>
					<p>При регистрации придумайте пароль</p>
				</div>
				<FormWrapper
					methods={methods}
					onSubmit={!email ? regSubmit : regEmployee}
				>
					{!email && (
						<Input
							placeholder='Email'
							control={methods.control}
							label='Email'
							required
							name='email'
							error={methods.formState.errors}
							rules={{
								required: 'Обязательное поле для заполнения',
								pattern: {
									value: REGEX_VERIFY.EMAIL_REGEX,
									message: 'Укажите валидный email'
								}
							}}
						/>
					)}

					<Input
						placeholder='Пароль'
						control={methods.control}
						label='Пароль'
						isPassword
						required
						name='password1'
						error={methods.formState.errors}
						rules={{ required: 'Обязательное поле для заполнения' }}
					/>

					<Input
						placeholder='Повторите пароль'
						control={methods.control}
						label='Повторите пароль'
						required
						isPassword
						name='password2'
						error={methods.formState.errors}
						rules={{
							required: 'Обязательное поле для заполнения',
							validate: value => {
								const { password1 } = methods.getValues();
								return value === password1 || 'Пароли не совпадают';
							}
						}}
					/>

					<div>
						<span>Уже зарегистрированы? </span>
						<Link className='reset-password' to={'/login'}>
							Войти
						</Link>
					</div>
					<button className='btn' type='submit'>
						Зарегистрироваться
					</button>
				</FormWrapper>

				{/* //TODO временно скрыт */}
				{/* <RegServices /> */}
			</div>
		</div>
	);
};

export default RegisterForm;
