import { useQuery } from '@tanstack/react-query';
import CompanyService from 'shared/services/company.service';

export const useUsersCompany = () => {
	const { isPending, isError, isSuccess, data, ...rest } = useQuery({
		queryFn: () => CompanyService.getUsersCompany(),
		queryKey: ['get users company'],
		select: ({ data }) => data,
		retry: 0
	});

	return { isPending, isError, isSuccess, companyUsersData: data, ...rest };
};
