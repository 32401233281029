import clsx from 'clsx';
import { FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAlertMessage } from 'shared/hooks';

interface IProps {
	error: any;
	isWindow?: boolean;
}
export const BlurOverlay: FC<IProps> = ({ error, isWindow }) => {
	const { getMessageError } = useAlertMessage();
	const navigate = useNavigate();
	const errorKey = getMessageError(error);

	const errorNavigation = {
		'Пользователь не подтверждён': {
			message: 'Пользователь не подтверждён. Подтвердите email в разделе ',
			link: '/account/profile',
			linkMessage: 'Профиль компании'
		},
		'Пользователь не найден': {
			message: 'Для просмотра необходимо авторизоваться',
			link: '/login',
			linkMessage: 'Вход '
		},
		dev: {
			message: 'Раздел находится в разработке'
		},
		any: {
			message: 'Возникла ошибка'
		}
	};

	//@ts-ignore
	const errorInfo = errorNavigation[errorKey] || errorNavigation.any;

	return (
		<div>
			<div className={clsx('profile-overlay ', !isWindow && 'fixed')}>
				<div className='profile-overlay-wrapper'>
					<div className='profile-overlay-content'>
						<p>
							{errorInfo?.message || errorInfo.any}{' '}
							{errorInfo?.link && (
								<Link to={errorInfo?.link}>“{errorInfo?.linkMessage}”</Link>
							)}
						</p>
					</div>
					{!isWindow && (
						<div className='profile-overlay-close'>
							<p>
								<button onClick={() => navigate(-1)}>
									<svg
										height='20'
										width='20'
										viewBox='0 0 20 20'
										aria-hidden='true'
										focusable='false'
										className='css-tj5bde-Svg'
									>
										<path d='M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z'></path>
									</svg>
								</button>
							</p>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
