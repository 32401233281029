import {
	MobReverseListIcon,
	MobSettingsSearchBarIcon,
	MobSortIcon,
	ReverseListIcon,
	SearchIcon,
	SettingsMobIcon,
	SettingsSearchBarIcon,
	SortIcon
} from 'assets/SearchBarIcons';
import clsx from 'clsx';
import { VersionBlock } from 'entities/version-block';
import { ButtonUp } from 'features/button-up';
import { useProductList } from 'features/product/api';
import { useGetTariff } from 'features/tariff/api/useGetTariff';
import { useGetCountries, useGetCurrencies } from 'features/utils/api';
import { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CommonContext from 'services/AuthContext';
import { useQueryParams } from 'shared/hooks';
import { TProduct, TSupplier } from 'shared/services/types';
import { ShowMore } from 'shared/ui/form/show-more';
import { Loader } from 'shared/ui/loader';
import { CurrencyBlock } from 'widgets/currency-block';
import './ProductPage.scss';
import { getQueryParams } from './helders';
import { TableProducts } from './table';

type TDistributerList = {
	name: string;
	help: string;
	value: TSupplier.SupplerType;
};
export const distributerList: TDistributerList[] = [
	{
		name: 'Дистрибьюторы',
		help: 'Дистрибьютор',
		value: 'DISTRIBUTOR'
	},
	{
		name: 'Сетевые магазины',
		value: 'CHAIN_STORE',
		help: 'Сетевой магазин'
	},
	{
		name: 'Магазины',
		value: 'STORE',
		help: 'Магазин'
	},
	{
		name: 'Партнеры KIRA',
		value: 'PARTNER',
		help: 'Партнер'
	},
	{
		name: 'Производители',
		help: 'Производитель',
		value: 'MANUFACTURER'
	}
];

const defaultSettings = [
	'country_id',
	'article',
	'id',
	'name',
	'prices',
	'stock_quantity',
	'stock_available',
	'transit_available',
	'supplier',
	'user_projects',
	'promo_url'
];

const ProductPage = () => {
	const params = useQueryParams() as any;

	const [searchText, setSearchText] = useState('');
	const [pagesData, setPagesData] = useState<any>();
	const [productsList, setProductsList] = useState<TProduct.Data[] | null>(
		null
	);
	const [emptyProducts, setEmptyProducts] = useState(false);
	const [openSidebar, setOpenSidebar] = useState(false);
	const [isEmptySearch, setIsEmptySearch] = useState(false);

	const [sort, setSort] = useState<'asc' | 'desc' | null>(
		params?.order_by === '-price' ? 'desc' : 'asc'
	);

	const defaultSort = { price: 'asc' };
	const defaultSortParams = params?.order_by
		? {
				[params?.order_by.replace('-', '')]: params?.order_by.includes('-')
					? 'desc'
					: 'asc'
		  }
		: defaultSort;

	const [sortTable, setSortTable] =
		useState<Record<any, any>>(defaultSortParams);

	const [openTableSettings, setOpenTableSettings] = useState(false);
	const [openAvailableFilter, setOpenAvailableFilter] = useState(true);
	const [openCountriesFilter, setOpenCountriesFilter] = useState(true);
	const [openManufacturerFilter, setOpenManufacturerFilter] = useState(true);
	const { data, tariffName: tariff } = useGetTariff();

	const { productList } = useProductList(params, 50);
	const { data: countries } = useGetCountries();
	const { data: currencies } = useGetCurrencies();

	const [searchbarButtonsMob, setSearchbarButtonsMob] = useState(false);
	const { authState, currentCurrency } = useContext(CommonContext);

	const currencyValue = currentCurrency.value || 'RUB';

	const [quantityParam, setQuantityParam] = useState<any[]>([]);
	const [hasWarehouse, setHasWarehouse] = useState<any>();

	const [countryParam, setCountryParam] = useState<string[]>([]);

	const [distributerParam, setDistributerParam] = useState<string[]>(
		getQueryParams()?.supplier_type || []
	);

	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		setSearchText(value);
	};

	const location = useLocation();
	const navigate = useNavigate();

	const toggleSidebar = () => {
		setOpenSidebar(!openSidebar);
	};

	const toggleSearchbarButtonsMob = () => {
		setSearchbarButtonsMob(!searchbarButtonsMob);
	};

	const toggleTableSettings = () => {
		setOpenTableSettings(!openTableSettings);
	};

	const [settingsParam, setSettingsParam] = useState<string[] | undefined>(
		defaultSettings
	);

	const [newSettingsParam, setNewSettingsParam] = useState(defaultSettings);

	const onQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const idQuantity = e.target.value;

		setQuantityParam((prev: string | string[]) => {
			if (prev === undefined) return prev;

			if (!Array.isArray(prev))
				return [prev].includes(idQuantity)
					? [prev].filter(id => id !== idQuantity)
					: [prev, idQuantity];

			return prev.includes(idQuantity)
				? prev.filter(id => id !== idQuantity)
				: [...prev, idQuantity];
		});
	};

	const onWareHouseChange = () => {
		setHasWarehouse((prev: any) => (!prev ? '0' : undefined));
	};

	const onCountryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const idCountry = e.target.value;
		setCountryParam(prev => {
			return prev.includes(idCountry)
				? prev.filter(id => id !== idCountry)
				: [...prev, idCountry];
		});
	};

	const onDistributerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const idCountry = e.target.value;
		setDistributerParam(prev => {
			return prev.includes(idCountry)
				? prev.filter(id => id !== idCountry)
				: [...prev, idCountry];
		});
	};

	const onSettingsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		if (newSettingsParam.includes(value)) {
			setNewSettingsParam(newSettingsParam.filter(param => param !== value));
		} else {
			setNewSettingsParam([...newSettingsParam, value]);
		}
		setOpenSidebar(false);
	};

	const getProducts = async () => {
		const currentPath = window.location.href.split('/');
		const hasWareHouseUrl = getQueryParams()?.has_warehouse;
		const quantity = getQueryParams()?.quantity;

		const search = getQueryParams()?.search;
		setSearchText(decodeURI(search || '').replace('$', '#'));

		setQuantityParam(quantity || []);
		if (hasWareHouseUrl) setHasWarehouse(hasWareHouseUrl);

		if (currentPath.length > 4) {
			const path1 = currentPath[4].split('?')[1];
			const path2 = path1?.split('&');
			const path3 = path2?.find(element => element?.includes('fields='));
			const path4 = path3?.split('=')[1].split(',');

			if (path3) {
				setSettingsParam(path4);
			} else {
				setSettingsParam(defaultSettings);
			}
			if (path4) setNewSettingsParam(path4);
		} else {
			setProductsList(null);
			setPagesData(null);
		}
	};

	useEffect(() => {
		const { data, error } = productList;

		if (data?.results?.length && !error) {
			setEmptyProducts(false);
			setPagesData(data);

			setProductsList(data.results);
		} else if ((data?.results && error) || error) {
			setEmptyProducts(true);
			setPagesData(null);

			setProductsList(null);
		}
	}, [productList.data?.results, productList.error]);

	useLayoutEffect(() => {
		getProducts();
		// setEmptyProducts(false);
		setIsEmptySearch(false);
	}, [location.pathname, location.search]);

	useLayoutEffect(() => {
		if (getQueryParams()?.search) {
			setSearchPath();
		}
	}, [currencyValue, !!getQueryParams()?.search, distributerParam]);

	const setSearchPath = (isChange?: boolean) => {
		const search = getQueryParams()?.search;
		const searchRes = (searchText || search)?.replace('#', '$');

		setOpenTableSettings(false);
		setOpenSidebar(false);
		if (productList.isLoading) return;
		if (!search?.trim() && !searchText?.trim()) {
			return setIsEmptySearch(true);
		}
		setIsEmptySearch(false);
		const joinedParams = [...newSettingsParam].join(',');

		let sortQuery = sort
			? sort === 'asc'
				? '&order_by=price'
				: '&order_by=-price'
			: '';

		const [key, value] = Object.entries(sortTable)[0];

		if (isChange) {
			const newSort = value === 'asc' ? '' : '-';
			sortQuery = `&order_by=${newSort}${key}`;
		}

		const searchPath = `/product/?${
			searchRes ? `search=${searchRes}&` : ''
		}currency_id=${currencyValue}${
			quantityParam?.length ? `&quantity=${quantityParam}` : ''
		}${
			countryParam.length < (countries?.length || 0) && countryParam.length
				? `&countries_ids=${countryParam}`
				: ''
		}${
			distributerParam?.length < (distributerList.length || 0) &&
			distributerParam?.length
				? `&supplier_type=${distributerParam}`
				: ''
		}${newSettingsParam.length !== 12 ? `&fields=${joinedParams}` : ''}${
			hasWarehouse ? `&has_warehouse=${hasWarehouse}` : ''
		}${sortQuery}`;

		navigate(searchPath);
	};

	useEffect(() => {
		if (location.pathname === '/') {
			setSortTable(defaultSort);
			setSearchText('');
		}
	}, [location]);

	const onSortTableProducts = (name: string) => {
		const newSort = sortTable[name]
			? { [name]: sortTable[name] === 'asc' ? 'desc' : 'asc' }
			: { [name]: 'asc' };

		setSortTable(newSort);
	};

	const isNumberValue = (value: any) => {
		if (!value) return 0;
		return isNaN(value) ? value.length : value;
	};

	useEffect(() => {
		if (productList.data.results) {
			setSearchPath(true);
		}
	}, [sortTable]);

	return (
		<section className='product-page'>
			<div className='container main'>
				{productList.isLoading && <Loader isFixed />}
				<div className='product-page__row '>
					<div className={'row container'}>
						<CurrencyBlock />
						<VersionBlock />
					</div>
					<div
						className={clsx(
							'product-searchbar',
							searchbarButtonsMob && 'index-top'
						)}
					>
						<form
							onSubmit={e => {
								e.preventDefault();
								setSearchPath();
							}}
						>
							<div className='product-searchbar-block'>
								<input
									value={searchText}
									onChange={handleSearchChange}
									type='text'
									placeholder='Введите артикул или часть названия'
									required
								/>
								<button
									type='button'
									onClick={() => onSortTableProducts('price')}
									className={clsx(
										'product-searchbar-sort',
										!sortTable?.price && 'default',
										sortTable?.price === 'desc' &&
											`product-searchbar-sort--reverse`
									)}
								>
									<ReverseListIcon />
								</button>
							</div>
							<div className='product-searchbar-buttons'>
								<button className='product-searchbar-button'>
									<SearchIcon />
								</button>
								<button
									type='button'
									className='product-searchbar-button mini'
									onClick={toggleTableSettings}
								>
									<SettingsSearchBarIcon />
								</button>
								<button
									type='button'
									className='product-searchbar-button mini'
									onClick={toggleSidebar}
								>
									<SortIcon />
								</button>
								<button
									type='button'
									className={clsx(
										'product-searchbar-button settings-mob',
										searchbarButtonsMob && 'active'
									)}
									onClick={toggleSearchbarButtonsMob}
								>
									<SettingsMobIcon />
								</button>
							</div>
							<div
								className={clsx('overlay', searchbarButtonsMob && 'open')}
								onClick={toggleSearchbarButtonsMob}
							/>

							<div
								className={`searchbar-buttons-modal ${
									searchbarButtonsMob ? 'show' : ''
								}`}
							>
								<button
									type='button'
									onClick={() => onSortTableProducts('price')}
									className={clsx(
										'product-searchbar-button',
										sortTable?.price === 'desc' && `reverse`
									)}
								>
									<MobReverseListIcon />
								</button>

								<button
									type='button'
									className='product-searchbar-button'
									onClick={toggleTableSettings}
								>
									<MobSettingsSearchBarIcon />
								</button>

								<button
									type='button'
									className='product-searchbar-button'
									onClick={toggleSidebar}
								>
									<MobSortIcon />
								</button>
							</div>
						</form>
					</div>

					{!!emptyProducts && (
						<p className='emptyproducts-message'>
							К сожалению, по данному запросу не было найдено нужных вам товаров
						</p>
					)}

					{!!isEmptySearch && (
						<p className='emptyproducts-message'>Заполните поле поиска</p>
					)}
					{!!productsList && !!countries && !!currencies && (
						<TableProducts
							authState={authState}
							countries={countries}
							currentCurrency={currentCurrency}
							data={data}
							sortTable={sortTable}
							onSortTableProducts={onSortTableProducts}
							tariff={tariff}
							settingsParam={settingsParam}
							productsList={productsList}
						/>
					)}

					{!!pagesData && (
						<div className='next-previous-page'>
							{productList.isFetching && !productList.isLoading && (
								<Loader isAbsolute isMini />
							)}

							{(productList.data.count || 0) >
								(productList.data.results?.length || 0) && (
								<ShowMore
									onClick={() => {
										productList.fetchNextPage();
									}}
								/>
							)}
						</div>
					)}

					<div
						onClick={toggleSidebar}
						className={clsx('overlay', openSidebar && 'open')}
					/>
					<div className={clsx('sidebar', openSidebar && 'open')}>
						<nav className={`sidebar-nav`}>
							<button className='sidebar-close' onClick={toggleSidebar}>
								<svg
									width='37'
									height='37'
									viewBox='0 0 37 37'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										d='M22.875 14.125L14.125 22.875M14.125 14.125L22.8749 22.875'
										stroke='#8A7DE7'
										strokeWidth='1.5'
										strokeLinecap='round'
									/>
									<path
										d='M36 18.5C36 26.7496 36 30.8744 33.4372 33.4372C30.8744 36 26.7496 36 18.5 36C10.2504 36 6.12563 36 3.56282 33.4372C1 30.8744 1 26.7496 1 18.5C1 10.2504 1 6.12563 3.56282 3.56282C6.12563 1 10.2504 1 18.5 1C26.7496 1 30.8744 1 33.4372 3.56282C35.1412 5.26686 35.7122 7.66147 35.9036 11.5'
										stroke='#8A7DE7'
										strokeWidth='1.5'
										strokeLinecap='round'
									/>
								</svg>
							</button>
							<div>
								<p
									className={clsx(
										'sidebar-nav-filter-header',
										openAvailableFilter ? 'opened' : 'closed'
									)}
									onClick={() => setOpenAvailableFilter(!openAvailableFilter)}
								>
									<i>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='12'
											height='6'
											viewBox='0 0 12 6'
											fill='none'
										>
											<path
												d='M0.750609 0.75L6.00061 5.25L11.2506 0.75'
												stroke='#333333'
												strokeWidth='1.5'
												strokeLinecap='round'
												strokeLinejoin='round'
											/>
										</svg>
									</i>
									Фильтр по наличию
								</p>
								{openAvailableFilter && (
									<>
										<label className='sidebar-nav-options'>
											<input
												type='checkbox'
												name='quantity'
												value='0'
												checked={quantityParam?.includes('0')}
												onChange={onQuantityChange}
											/>
											<span>Только в наличии</span>
										</label>
										<label className='sidebar-nav-options'>
											<input
												type='checkbox'
												name='quantity'
												value='1'
												checked={quantityParam?.includes('1')}
												onChange={onQuantityChange}
											/>
											<span>В наличии или в транзите</span>
										</label>
										<label className='sidebar-nav-options'>
											<input
												type='checkbox'
												name='warehouse'
												value={'0'}
												checked={hasWarehouse === '0'}
												onChange={onWareHouseChange}
											/>
											<span>Без склада</span>
										</label>
									</>
								)}
							</div>
							<div>
								<p
									className={clsx(
										`sidebar-nav-filter-header`,
										openCountriesFilter ? 'opened' : 'closed'
									)}
									onClick={() => setOpenCountriesFilter(!openCountriesFilter)}
								>
									<i>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='12'
											height='6'
											viewBox='0 0 12 6'
											fill='none'
										>
											<path
												d='M0.750609 0.75L6.00061 5.25L11.2506 0.75'
												stroke='#333333'
												strokeWidth='1.5'
												strokeLinecap='round'
												strokeLinejoin='round'
											/>
										</svg>
									</i>
									Фильтр по странам
								</p>
								{!!(countries && openCountriesFilter) &&
									countries.map(i => {
										return (
											<label key={i.id} className='sidebar-nav-options'>
												<input
													type='checkbox'
													name='county'
													value={i.id}
													checked={countryParam?.includes(i.id.toString())}
													onChange={onCountryChange}
												/>
												<span>{i.name}</span>
											</label>
										);
									})}
							</div>
							<div>
								<p
									className={clsx(
										'sidebar-nav-filter-header',
										openManufacturerFilter ? 'opened' : 'closed'
									)}
									onClick={() =>
										setOpenManufacturerFilter(!openManufacturerFilter)
									}
								>
									<i>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='12'
											height='6'
											viewBox='0 0 12 6'
											fill='none'
										>
											<path
												d='M0.750609 0.75L6.00061 5.25L11.2506 0.75'
												stroke='#333333'
												strokeWidth='1.5'
												strokeLinecap='round'
												strokeLinejoin='round'
											/>
										</svg>
									</i>
									Выбор поставщика
								</p>
								{openManufacturerFilter && (
									<>
										{distributerList.map(item => (
											<label className='sidebar-nav-options' key={item.value}>
												<input
													type='checkbox'
													name='supplier'
													value={item.value}
													onChange={onDistributerChange}
													checked={distributerParam?.includes(item.value)}
												/>
												<span>{item.name}</span>
											</label>
										))}
									</>
								)}
							</div>{' '}
							<div>
								<button
									className='filter-apply'
									onClick={() => setSearchPath(true)}
								>
									Применить
								</button>
							</div>
						</nav>
					</div>
					<div
						onClick={toggleTableSettings}
						className={clsx('overlay', openTableSettings && 'open')}
					/>
					<div className={clsx('sidebar', openTableSettings && 'open')}>
						<nav className={`sidebar-nav`}>
							<button className='sidebar-close' onClick={toggleTableSettings}>
								<svg
									width='37'
									height='37'
									viewBox='0 0 37 37'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										d='M22.875 14.125L14.125 22.875M14.125 14.125L22.8749 22.875'
										stroke='#8A7DE7'
										strokeWidth='1.5'
										strokeLinecap='round'
									/>
									<path
										d='M36 18.5C36 26.7496 36 30.8744 33.4372 33.4372C30.8744 36 26.7496 36 18.5 36C10.2504 36 6.12563 36 3.56282 33.4372C1 30.8744 1 26.7496 1 18.5C1 10.2504 1 6.12563 3.56282 3.56282C6.12563 1 10.2504 1 18.5 1C26.7496 1 30.8744 1 33.4372 3.56282C35.1412 5.26686 35.7122 7.66147 35.9036 11.5'
										stroke='#8A7DE7'
										strokeWidth='1.5'
										strokeLinecap='round'
									/>
								</svg>
							</button>
							<div>
								<p className={`sidebar-nav-filter-header`}>Настройка таблицы</p>
								<label className='sidebar-nav-options'>
									<input
										type='checkbox'
										name='settings'
										value='country_id'
										checked={newSettingsParam.includes('country_id')}
										onChange={onSettingsChange}
									/>
									<span>Страна</span>
								</label>
								<label className='sidebar-nav-options'>
									<input
										type='checkbox'
										name='settings'
										disabled
										value='article'
										checked={newSettingsParam.includes('article')}
										onChange={onSettingsChange}
									/>
									<span>Артикул</span>
								</label>

								<label className='sidebar-nav-options'>
									<input
										type='checkbox'
										name='settings'
										disabled
										value='name'
										onChange={onSettingsChange}
										checked={newSettingsParam.includes('name')}
									/>
									<span>Название</span>
								</label>
								<label className='sidebar-nav-options'>
									<input
										type='checkbox'
										name='settings'
										value='prices'
										checked={newSettingsParam.includes('prices')}
										onChange={onSettingsChange}
									/>
									<span>Цены</span>
								</label>
								<label className='sidebar-nav-options'>
									<input
										type='checkbox'
										name='settings'
										value='stock_available'
										checked={newSettingsParam.includes('stock_available')}
										onChange={onSettingsChange}
									/>
									<span>Доступно</span>
								</label>
								<label className='sidebar-nav-options'>
									<input
										type='checkbox'
										name='settings'
										value='stock_quantity'
										checked={newSettingsParam.includes('stock_quantity')}
										onChange={onSettingsChange}
									/>
									<span>На складе</span>
								</label>
								<label className='sidebar-nav-options'>
									<input
										type='checkbox'
										name='settings'
										value='transit_available'
										checked={newSettingsParam.includes('transit_available')}
										onChange={onSettingsChange}
									/>
									<span>Транзит</span>
								</label>

								<label className='sidebar-nav-options'>
									<input
										type='checkbox'
										name='settings'
										value='supplier'
										checked={newSettingsParam.includes('supplier')}
										onChange={onSettingsChange}
									/>
									<span>Поставщик</span>
								</label>
							</div>
							<div>
								<button
									className='filter-apply'
									onClick={() => setSearchPath(true)}
								>
									Применить
								</button>
							</div>
						</nav>
					</div>
				</div>
			</div>
			<ButtonUp />
		</section>
	);
};

export default ProductPage;
