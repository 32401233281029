import 'assets/Numbers';
import { FirstNumber, SecondNumber } from 'assets/Numbers';
import 'assets/input.scss';
import { useEffect, useState } from 'react';
import Dropdown from 'shared/ui/dropdown';
import './SourcesStatistics.scss';

import BannerSupport from 'entities/banner-support';
import { PartnerPermissionModal } from 'entities/partners/permission-modal';
import { usePriceSource } from 'features/profile/api/usePriceSource';
import { useGetTariff } from 'features/tariff/api/useGetTariff';
import { Link } from 'react-router-dom';
import { useRoleUser } from 'shared/hooks';
import DataSource from './DataSource';
import DownloadMethod from './DownloadMethod';
import StatisticsDiagram from './StatisticsDiagram';

const partnerListAccess = [
	'Партнёр',
	'Корпоративный партнёр',
	`Прайс-лист с меткой "Под заказ"`,
	`Размещение прайс-листа`
];

const SourcesStatistics = () => {
	const { isOwnerCompany } = useRoleUser();
	const { getSource } = usePriceSource();
	const [showOverlay, setShowOverlay] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const { tariffName, isPending } = useGetTariff();

	const onCloseModal = () => {
		setIsOpen(false);
	};
	useEffect(() => {
		if (isPending) return;
		if (getSource.error) {
			//@ts-ignore
			setShowOverlay(getSource.error?.response?.data?.detail);
		}
		if (!partnerListAccess.includes(tariffName) && isOwnerCompany) {
			setIsOpen(true);
			setShowOverlay(true);
		}
	}, [getSource.error, isPending]);

	const [isModalVisible, setModalVisible] = useState(false);

	const openModal = () => {
		setModalVisible(true);
	};

	const closeModal = () => {
		setModalVisible(false);
	};

	return (
		<>
			<div className='sources-statistics'>
				<div className='profile-container'>
					<div className='warning-text'>
						<p>Чтобы ваше предложение стало доступно, следуйте инструкции.</p>
					</div>
					<Dropdown
						title='Источник данных'
						content={<DataSource />}
						number={<FirstNumber />}
					/>
					<Dropdown
						title='Способ загрузки'
						content={<DownloadMethod />}
						number={<SecondNumber />}
					/>
				</div>

				<StatisticsDiagram sourceList={getSource?.data || []} />
			</div>
			<div className='sources-statistics-support'>
				<BannerSupport />
			</div>

			{!!showOverlay && (
				<div className='profile-overlay'>
					<div className='profile-overlay-content'>
						<p>
							{typeof showOverlay === 'string' ? (
								<>{'Раздел находится в разработке'}</>
							) : (
								<>
									{isOwnerCompany ? (
										<>
											Для работы с источниками смените тариф на подходящий в
											разделе
											<Link to='/account/tariffs'> “Тарифы и оплата”</Link>
										</>
									) : (
										<>Для работы с источниками нужно быть владельцем компании</>
									)}
								</>
							)}
						</p>
					</div>
				</div>
			)}
			<PartnerPermissionModal
				isOpen={isOpen}
				onClose={onCloseModal}
				title='Работать с источниками можно на:'
				data={partnerListAccess}
			/>
		</>
	);
};

export default SourcesStatistics;
