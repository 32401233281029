import { useQuery, useQueryClient } from '@tanstack/react-query';
import StatsService from 'shared/services/stats.service';
import { TStats } from 'shared/services/types';

export const useGetStatistics = (id?: number, data?: TStats.Filter) => {
	const queryClient = useQueryClient();

	const getCountProductPartner = useQuery({
		queryFn: () => StatsService.getCountProductPartner(id!, data),
		queryKey: ['getCountProductPartner'],
		select: ({ data }) => data,
		retry: 0,
		enabled: !!id
	});

	const getViewPartner = useQuery({
		queryFn: () => StatsService.getViewPartner(id!, data),
		queryKey: ['getViewPartner', data],
		enabled: !!id,
		retry: 0,
		select: ({ data }) => data
	});

	const getViewProductPartner = useQuery({
		queryFn: () => StatsService.getViewProductPartner(id!, data),
		queryKey: ['getViewProductPartner'],
		enabled: !!id,
		retry: 0,
		select: ({ data }) => data
	});
	return {
		getCountProductPartner,
		getViewPartner,
		getViewProductPartner
	};
};
