import { FileIcon } from 'assets/ChatIcons';
import React, { FC, useEffect, useRef } from 'react';

interface IProps {
	onChange?: (e: File) => void;
	isClear?: boolean;
}

const FileUploadButton: FC<IProps> = ({ onChange, isClear }) => {
	const fileInputRef = useRef<HTMLInputElement>(null);

	const handleClick = () => {
		fileInputRef?.current?.click?.();
	};

	useEffect(() => {
		if (isClear && fileInputRef?.current) {
			fileInputRef.current.value = '';
		}
	}, [isClear]);
	const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files?.[0];
		const maxSizeInBytes = 5 * 1024 * 1024;

		if (file && !file.type.includes('image')) {
			alert('Не поддерживаемый формат файла');
			e.target.value = '';
			return;
		}

		if (file && onChange && file.size < maxSizeInBytes) {
			onChange(file);
		} else if (file && onChange && file.size > maxSizeInBytes) {
			alert('Размер файла превышает допустимый лимит');
			e.target.value = '';
			return;
		}
	};

	return (
		<>
			<button className='chat-footer-file' onClick={handleClick}>
				<FileIcon />
			</button>
			<input
				type='file'
				ref={fileInputRef}
				style={{ display: 'none' }}
				onChange={handleFileChange}
			/>
		</>
	);
};

export default FileUploadButton;
