import { useEffect, useState } from 'react';
import { tokenParse } from 'shared/utils';
import './ProfileInfoUser.scss';

import { useAuthActions } from 'features/auth/api';
import { useGetUser } from 'features/profile/api/useGetUser';
import { useUpdateUser } from 'features/profile/api/useUpdateUser';
import {
	useGetCountries,
	useGetCurrencies,
	useGetLanguages
} from 'features/utils/api';
import { FieldValues, useForm } from 'react-hook-form';
import { URL_SITE } from 'shared/constants';
import { useAlertMessage, useQueryParams } from 'shared/hooks';
import { TUser } from 'shared/services/types';
import { FormWrapper } from 'shared/ui/form/form-wrapper';
import { Input } from 'shared/ui/form/input';
import { ReactSelect } from 'shared/ui/form/react-select';
import { MyOptionType } from 'shared/ui/form/types';
import { maskPhone } from '../../constants';
import { REGEX_VERIFY } from 'shared/regex';

const genderOptions = [
	{ label: 'Муж', value: 'male' },
	{ label: 'Жен', value: 'female' }
];

const ProfileInfoUser = () => {
	const { addAlertMessage } = useAlertMessage();
	const { onConfirmChangeEmail, onVerifyEmail } = useAuthActions();
	const info = tokenParse();
	const methods = useForm();
	const {
		watch,
		control,
		formState: { errors }
	} = methods;
	const params = useQueryParams();

	const { userData } = useGetUser();

	useEffect(() => {
		if (userData?.email && params.token && !userData?.is_verified) {
			onConfirmChangeEmail
				.mutateAsync({ email: userData.email, token: params.token })
				.then(() => {
					addAlertMessage('Email успешно подтвержден', 'success');
				})
				.catch(err => {
					addAlertMessage(err, 'error');
				});
		}
	}, [userData, params.token]);

	const { data: dataLanguages } = useGetLanguages();
	const { data: dataCountries } = useGetCountries();
	const { data: dataCurrencies } = useGetCurrencies();

	const { updateUserAsync } = useUpdateUser(info.user_id);

	const [countriesData, setCountriesData] = useState<MyOptionType[]>([]);
	const [currenciesData, setCurrenciesData] = useState<MyOptionType[]>([]);
	const [languagesData, setLanguagesData] = useState<MyOptionType[]>([]);

	const infoSubmit = async (data: FieldValues) => {
		try {
			const userData = {
				email: data.email,
				phone_number: data?.phone_number,
				first_name: data?.first_name,
				last_name: data?.last_name,
				gender: data?.gender?.value,
				country_id: data?.country?.value,
				currency_id: data?.currency?.label,
				spoken_language_id: data?.spoken_language?.value
			};

			await updateUserAsync(userData as TUser.Update);
			addAlertMessage('Профиль успешно обновлен', 'success');
		} catch (error: any) {
			addAlertMessage(error, 'error');
		}
	};

	const onConfirmEmailUrl = async () => {
		try {
			await onVerifyEmail.mutateAsync(
				window.location.origin + '/account/profile'
			);
			addAlertMessage('Ссылка для подтверждения выслана на почту', 'info');
		} catch (error: any) {
			addAlertMessage(error, 'error');
		}
	};

	const getUserData = () => {
		if (!userData) return null;

		methods.reset({ ...userData });
		methods.setValue(
			'gender',
			genderOptions?.find(item => item.value === userData?.gender)
		);
		methods.setValue(
			'country',
			countriesData?.find(item => item.value === userData?.country?.id)
		);

		methods.setValue(
			'spoken_language',
			languagesData?.find(item => item.value === userData?.spoken_language?.id)
		);
		methods.setValue(
			'currency',
			currenciesData?.find(
				item => item.value === userData?.currency?.country_id
			)
		);
	};

	const fetchSelectData = async () => {
		const data = dataCountries
			// ?.filter(item => ![2, 4].includes(item.id))
			?.map(item => ({
				label: item.name,
				value: item.id,
				flag: URL_SITE + item.flag
			}));
		setCountriesData(data || []);

		const currenciesResponseData = dataCurrencies?.map(i => {
			const foundFlag = dataCountries?.find(k => k.id === i.country_id);

			return {
				value: i.country_id,
				label: i.id,
				flag: URL_SITE + foundFlag?.flag
			};
		});

		setCurrenciesData(currenciesResponseData || []);

		const languagesResponseData = dataLanguages?.map(i => {
			const foundFlag = dataCountries?.find(k => k.id === i.country_id);

			return {
				value: i.id,
				label: i.name,
				flag: URL_SITE + foundFlag?.flag
			};
		});

		setLanguagesData(languagesResponseData || []);
	};

	useEffect(() => {
		fetchSelectData();
	}, [dataCurrencies, dataLanguages, dataCountries]);

	useEffect(() => {
		getUserData();
	}, [userData, countriesData, currenciesData, countriesData]);

	return (
		<FormWrapper
			methods={methods}
			onSubmit={infoSubmit}
			className='profileuser-form'
		>
			<div className='inputs-row'>
				<Input
					control={control}
					name='first_name'
					label='Имя'
					placeholder='Имя'
					required
					error={errors}
					rules={{ required: 'Обязательное поле для заполнения' }}
				/>

				<Input
					control={control}
					name='last_name'
					label='Фамилия'
					placeholder='Фамилия'
					required
					error={errors}
					rules={{ required: 'Обязательное поле для заполнения' }}
				/>
				<ReactSelect
					className='select-gender'
					control={control}
					name='gender'
					label='Гендер'
					error={errors}
					rules={{ required: 'Обязательное поле для заполнения' }}
					required
					options={genderOptions}
				/>
			</div>
			<div className='inputs-row'>
				<Input
					control={control}
					name='email'
					label='Email'
					placeholder='Email'
					required
					error={errors}
					rules={{
						required: 'Обязательное поле для заполнения',
						pattern: {
							value: REGEX_VERIFY.EMAIL_REGEX,
							message: 'Укажите валидный email'
						}
					}}
				/>
				{!userData?.is_verified && (
					<div className='input-submit-block'>
						<button
							className='btn'
							type='button'
							disabled={onVerifyEmail.isPending}
							onClick={onConfirmEmailUrl}
						>
							Подтвердить
						</button>
					</div>
				)}
			</div>
			<div className='inputs-row'>
				<ReactSelect
					control={control}
					name='country'
					label='Страна проживания'
					required
					options={countriesData}
					rules={{ required: 'Обязательное поле для заполнения' }}
					disabled={!countriesData}
				/>

				<Input
					control={control}
					className='input-phone'
					name='phone_number'
					label='Введите номер'
					required
					//@ts-ignore
					mask={maskPhone[watch('country')?.label]?.mask}
					error={errors}
					rules={{
						required: 'Обязательное поле для заполнения',
						pattern: {
							message: 'Введите корректный номер телефона',
							value: /^((?!\_).)*$/
						}
					}}
					placeholder={
						//@ts-ignore
						maskPhone[watch('country')?.label]?.placeholder || 'Введите номер'
					}
				/>
			</div>
			<div className='inputs-row'>
				<ReactSelect
					control={control}
					name='spoken_language'
					label='Разговорный язык'
					options={languagesData}
					disabled={!languagesData}
				/>

				<ReactSelect
					control={control}
					name='currency'
					label='Ваша валюта'
					options={currenciesData}
					disabled={!currenciesData}
				/>
			</div>

			<div className='block-save'>
				<button className='btn' type='submit'>
					Сохранить
				</button>
				{/* {!isPending && isSuccess && (
					<div className='date-save'>Сохранено:{currentDate()}</div>
				)} */}
			</div>
		</FormWrapper>
	);
};

export default ProfileInfoUser;
