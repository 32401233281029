import { useUpdateUser } from 'features/profile/api';
import { FC, useEffect } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import Popup from 'reactjs-popup';
import { useAlertMessage } from 'shared/hooks';
import { REGEX_VERIFY } from 'shared/regex';
import { TCompany } from 'shared/services/types';
import { FormWrapper } from 'shared/ui/form/form-wrapper';
import { Input } from 'shared/ui/form/input';

interface IProps {
	data: TCompany.EmployeesCompany;
	isOpen: boolean;
	onClose: () => void;
}
export const ChangeUserPopup: FC<IProps> = ({ isOpen, onClose, data }) => {
	const methods = useForm();
	const { addAlertMessage } = useAlertMessage();
	const { updateUserAsync } = useUpdateUser(data.id);

	const handlePopupClose = () => {
		onClose();
	};

	useEffect(() => {
		if (isOpen) {
			methods.reset({
				email: data.email,
				fio: data.first_name + ' ' + data.last_name
			});
		}
	}, [data, isOpen]);

	const changeEmployee = async (formData: FieldValues) => {
		const fioParsed = formData.fio.split(' ');
		const employeeData = {
			email: formData.email,
			last_name: fioParsed[0],
			first_name: fioParsed[1]
		};

		try {
			await updateUserAsync(employeeData);
			handlePopupClose();
		} catch (error: any) {
			addAlertMessage(error, 'error');
		}
	};

	return (
		<Popup
			className='employees-popup-base'
			open={isOpen}
			closeOnDocumentClick
			onClose={handlePopupClose}
		>
			<div>
				<div className='distributor-popup-header'>
					<h3>Редактировать сотрудника</h3>
					<button type='button' onClick={handlePopupClose}>
						<svg
							width='32'
							height='32'
							viewBox='0 0 32 32'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								d='M19.75 12.25L12.25 19.75M12.25 12.25L19.7499 19.75'
								stroke='#8A7DE7'
								strokeWidth='1.5'
								strokeLinecap='round'
							/>
							<path
								d='M31 16C31 23.0711 31 26.6066 28.8033 28.8033C26.6066 31 23.0711 31 16 31C8.92893 31 5.3934 31 3.1967 28.8033C1 26.6066 1 23.0711 1 16C1 8.92893 1 5.3934 3.1967 3.1967C5.3934 1 8.92893 1 16 1C23.0711 1 26.6066 1 28.8033 3.1967C30.2639 4.65731 30.7533 6.70983 30.9173 10'
								stroke='#8A7DE7'
								stroke-width='1.5'
								stroke-linecap='round'
							/>
						</svg>
					</button>
				</div>

				<div className='tariff-popup-content'>
					<FormWrapper
						className='distributors-form'
						onSubmit={changeEmployee}
						methods={methods}
					>
						<Input
							control={methods.control}
							name='fio'
							label='ФИО:'
							required
							error={methods.formState.errors}
							rules={{
								required: 'Обязательное поле для заполнения'
							}}
						/>
						<Input
							control={methods.control}
							name='email'
							label='Email:'
							placeholder='Email:'
							required
							error={methods.formState.errors}
							rules={{
								required: 'Обязательное поле для заполнения',
								pattern: {
									value: REGEX_VERIFY.EMAIL_REGEX,
									message: 'Укажите валидный email'
								}
							}}
						/>

						<button className='btn' type='submit'>
							Применить изменения
						</button>
					</FormWrapper>
				</div>
			</div>
		</Popup>
	);
};
