import { TReview } from './types';

import { axiosInstance } from 'shared/api';
import { getReviewUrl } from 'shared/api/config';

const ReviewService = {
	async createReview(data?: TReview.Create) {
		const response = await axiosInstance.post<TReview.DataMini>(
			getReviewUrl(`/supplier`),
			{ params: data }
		);
		return response;
	},

	async updateReview(id: number, data?: TReview.Update) {
		const response = await axiosInstance.patch<TReview.DataMini>(
			getReviewUrl(`/supplier/${id}`),
			{ params: data }
		);
		return response;
	},

	async deleteReview(id: number) {
		const response = await axiosInstance.delete<string>(
			getReviewUrl(`/supplier/${id}`)
		);
		return response;
	},

	async getReviewList(data?: TReview.Filter) {
		const { supplier_id, ...rest } = data || {};
		const response = await axiosInstance.get<TReview.ResponseReviewList>(
			getReviewUrl(`/supplier/list/${supplier_id}`),
			{ params: rest }
		);
		return response;
	},

	async createComplaint(data?: TReview.CreateComplaint) {
		const response = await axiosInstance.post<TReview.Complaint>(
			getReviewUrl(`/supplier/complaint`),
			data
		);
		return response;
	},

	async putAReaction(data?: TReview.ReactionUpdate) {
		const response = await axiosInstance.post<TReview.Reaction>(
			getReviewUrl(`/supplier/reaction`),
			data
		);
		return response;
	}
};

export default ReviewService;
