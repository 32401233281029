import clsx from 'clsx';
import { FC, useEffect, useState } from 'react';

type TSort = Record<string, 'desc' | 'asc'>;

const defaultSort: TSort = {
	created_at: 'desc'
};

const dataSort = [
	{
		label: 'Дата',
		name: 'created_at'
	},
	{
		label: 'Оценке',
		name: 'mark'
	},
	{
		label: 'Популярные',
		name: 'popularity'
	}
];

interface IProps {
	onSorting: (nameSort: string) => void;
}

export const ReviewsSort: FC<IProps> = ({ onSorting }) => {
	const [sort, setSort] = useState<TSort>(defaultSort);

	useEffect(() => {

		const sortName = Object.entries(sort)?.[0];
		const value = sortName[1] === 'desc' ? `-${sortName[0]}` : sortName[0];

		onSorting(value);
	}, [sort]);

	const onChangeSort = (name: string) => {
		setSort(prev => ({ [name]: prev[name] === 'asc' ? 'desc' : 'asc' }));
	};

	return (
		<div className='partner-reviews__sort'>
			<p>Сортировка по:</p>
			{dataSort.map(item => {
				return (
					<button
						type='button'
						onClick={() => onChangeSort(item.name)}
						className={clsx(sort[item.name] && 'active')}
					>
						{item.label}
						{!!sort[item.name] && <SortJSX down={sort[item.name] === 'desc'} />}
					</button>
				);
			})}
		</div>
	);
};

const SortJSX: FC<{ down?: boolean }> = ({ down }) => {
	return (
		<svg
			className={clsx(down && 'rotate')}
			xmlns='http://www.w3.org/2000/svg'
			width='15'
			height='16'
			viewBox='0 0 15 16'
			fill='none'
		>
			<g clipPath='url(#clip0_100_2663)'>
				<path
					d='M7.5 1.125L3.125 5.70833M7.5 1.125L11.875 5.70833M7.5 1.125V14.875'
					stroke='#8A7DE7'
					strokeWidth='1.2'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
			</g>
			<defs>
				<clipPath id='clip0_100_2663'>
					<rect
						x='15'
						y='15.5'
						width='15'
						height='15'
						rx='5'
						transform='rotate(180 15 15.5)'
						fill='white'
					/>
				</clipPath>
			</defs>
		</svg>
	);
};
