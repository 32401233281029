import { useMutation, useQueryClient } from '@tanstack/react-query';
import ProjectService from 'shared/services/project.service';
import { TProject } from 'shared/services/types/project.types';

export const useProjectsActions = (id?: number) => {
	const queryClient = useQueryClient();

	const createProject = useMutation({
		mutationFn: (data: TProject.Create) => ProjectService.create(data),
		mutationKey: ['create project'],
		onSuccess: ({ data }) => {
			queryClient.invalidateQueries({ queryKey: [`get project list`] });
			return data;
		}
	});

	const updateProject = useMutation({
		mutationFn: (data: Omit<TProject.Update, 'id'>) =>
			ProjectService.updateProject(id!, data),
		mutationKey: ['update project'],
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: [`get project list`] });
		}
	});

	const addProductProject = useMutation({
		mutationFn: (data: { data: TProject.AddProduct; id: number }) =>
			ProjectService.addProduct(data.id, data.data),
		mutationKey: ['add product project', id]
	});

	const deleteProject = useMutation({
		mutationFn: () => ProjectService.deleteProject(id!),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: [`get project list`] });
		},
		mutationKey: ['delete project', id]
	});

	return {
		addProductProject,
		createProject,
		deleteProject,
		updateProject
	};
};
