import { format, isThisYear, isToday, isYesterday, subDays } from 'date-fns';
import { ru } from 'date-fns/locale';
import { IChatHistory, IGroupedMessages } from 'widgets/chat/chat.interface';

export const getLocaleTimeChat = (
	timestamp?: string | null,
	nowTime?: boolean
) => {
	if (!timestamp) return null;

	const date = new Date(timestamp);

	if (isToday(date)) {
		return 'Сегодня';
	}

	if (isYesterday(date)) {
		return 'Вчера';
	}

	if (
		format(date, 'yyyy-MM-dd') === format(subDays(new Date(), 2), 'yyyy-MM-dd')
	) {
		return 'Позавчера';
	}

	if (isThisYear(date)) {
		return format(date, 'dd MMMM', { locale: ru });
	}

	return format(date, 'dd MMMM yy', { locale: ru });
};

export const groupByDate = (messages: IChatHistory[]): IGroupedMessages[] => {
	const grouped: IGroupedMessages[] = messages.reduce((acc, message) => {
		const dateKey = getLocaleTimeChat(message.created_at);

		// Проверяем, существует ли уже группа для этой даты
		let dateGroup = acc.find(group => group.date === dateKey);

		if (!dateGroup) {
			dateGroup = {
				date: dateKey || 'Неизвестная дата', // Обработка случая, если dateKey null
				items: []
			};
			acc.push(dateGroup);
		}

		dateGroup.items.push(message);
		return acc;
	}, [] as IGroupedMessages[]);

	return grouped;
};
