import {
	useInfiniteQuery,
	useMutation,
	useQueryClient
} from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { ReviewService } from 'shared/services';
import { TReview } from 'shared/services/types';

export const useGetReviews = (filter?: TReview.Filter) => {
	const queryClient = useQueryClient();

	const [dataList, setDataList] = useState<any | null>(null);

	const reviewsList = useInfiniteQuery({
		queryKey: ['get reviews list', filter],
		initialPageParam: 1,
		enabled: !!filter?.supplier_id,
		queryFn: ({ pageParam = 1 }) =>
			ReviewService.getReviewList({ ...filter!, page: pageParam }),
		getNextPageParam: (lastPage, allPages) => allPages.length + 1,
		retry: 0,
		select: data => {
			const res = data.pages.map(item => ({
				...item.data
			}));

			return { ...data, pages: res };
		}
	});

	useEffect(() => {
		const { data, isPending } = reviewsList;

		const result = data?.pages.reduce((acc, page) => {
			return {
				...page,
				//@ts-ignore
				results: [...(acc?.results || []), ...(page?.results?.reviews || [])],
				//@ts-ignore
				reviewsCount: page?.results?.reviews_count
			};
		}, {} as any);

		if (!isPending) setDataList(result);
	}, [reviewsList.data?.pages, reviewsList.isPending]);

	const addReview = useMutation({
		mutationFn: (data: TReview.Create) => ReviewService.createReview(data),
		mutationKey: ['add review'],
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: [`get reviews list`] });
		}
	});

	return {
		addReview,
		reviewsList: {
			...reviewsList,
			data: { ...reviewsList.data, ...dataList },
			reviewsCount: dataList?.reviewsCount
		}
	};
};
