import { ReadIcon } from 'assets/ChatIcons';
import clsx from 'clsx';
import { FC, Fragment } from 'react';
import { WEB_SITE } from 'shared/api/config';
import { imageExtension } from 'shared/constants';
import { getLocaleTime } from 'shared/utils';
import { IChatHistory } from 'widgets/chat/chat.interface';

interface IProps {
	i: IChatHistory;
	isMe?: boolean;
	isAdmin?: boolean;
}
export const MessageItem: FC<IProps> = ({ i, isMe, isAdmin }) => {
	const file = i?.file;
	const filePath =
		typeof file === 'string'
			? WEB_SITE + file
			: (file && URL.createObjectURL(file)) || '';

	const createdDate = getLocaleTime(i.created_at, i.isCreate, 'HH:mm');

	const fileName =
		typeof file === 'string' ? file : file instanceof File ? file.name : '';

	const extension = fileName.split('.').at(-1) || '';

	return (
		<Fragment key={i?.id}>
			{i.file && (
				<div className={clsx('message', 'file', !isMe ? 'sender' : 'admin')}>
					{isMe ? (
						<>
							{i.file && !i.content && (
								<h1>{isAdmin ? 'Пользователь' : 'Админ'}</h1>
							)}

							<div className='message__body'>
								{imageExtension.includes(extension || '') ? (
									<a
										href={filePath}
										download={filePath}
										style={{ display: 'block' }}
										target='_blank'
									>
										<img
											src={filePath}
											alt={filePath}
											style={{ width: '100%' }}
										/>
									</a>
								) : (
									<a
										href={filePath}
										download={filePath}
										style={{ display: 'block' }}
									>
										{filePath}
									</a>
								)}
								<div className='message-info'>
									<p>{createdDate}</p>
								</div>
							</div>
						</>
					) : (
						<>
							{imageExtension.includes(extension) ? (
								<a
									href={filePath}
									download={filePath}
									style={{ display: 'block' }}
									target='_blank'
								>
									<img
										src={filePath}
										alt={filePath}
										style={{ width: '100%' }}
									/>
								</a>
							) : (
								<a
									href={filePath}
									download={filePath}
									style={{ display: 'block' }}
								>
									{filePath}
								</a>
							)}
							<div className='message-info'>
								{!!i.is_read && (
									<p>
										<ReadIcon />
									</p>
								)}
								<p>{createdDate}</p>
							</div>
						</>
					)}
				</div>
			)}
			{!!i.content && (
				<div className={clsx('message', !isMe ? 'sender' : 'admin')}>
					{isMe ? (
						<>
							<h1>{isAdmin ? 'Пользователь' : 'Админ'}</h1>
							<div className='message__body'>
								<p>{i.content}</p>
								<div className='message-info'>
									<p>{createdDate}</p>
								</div>
							</div>
						</>
					) : (
						<>
							<div className='message__body'>
								<p>{i.content}</p>
								<div className='message-info'>
									<p>{!!i.is_read && <ReadIcon />}</p>
									<p>{createdDate}</p>
								</div>
							</div>
						</>
					)}
				</div>
			)}
		</Fragment>
	);
};
