import { useInfiniteQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import SupplierService from 'shared/services/supplier.service';
import { TSupplier } from 'shared/services/types';

export const useSupplierList = (filter?: TSupplier.Filter) => {
	const [dataList, setDataList] = useState<TSupplier.Response | null>(null);

	const supplierData = useInfiniteQuery({
		queryKey: ['get supplier list', filter],
		initialPageParam: 1,
		queryFn: ({ pageParam = 1 }) =>
			SupplierService.getList({
				...filter!,
				page: pageParam,
				page_size: filter?.page_size
			}),
		getNextPageParam: (lastPage, allPages) => allPages.length + 1,
		retry: 0,
		select: data => {
			const res = data.pages.map(item => ({
				...item.data
			}));

			return { ...data, pages: res };
		}
	});

	useEffect(() => {
		if (supplierData.error) {
			setDataList(null);
		}
	}, [supplierData.error]);

	useEffect(() => {
		if (supplierData.isSuccess) {
			const result = supplierData.data.pages.reduce(
				(acc, page) => {
					return {
						...acc,
						results: [...(acc?.results || []), ...(page?.results || [])]
					};
				},
				{ results: [] } as any
			);

			setDataList(result);
		}
	}, [supplierData.isSuccess, supplierData.data]);

	return {
		...supplierData,
		supplierData: {
			...dataList
		}
	};
};
