import { useMutation, useQueryClient } from '@tanstack/react-query';
import CompanyService from 'shared/services/company.service';

export const useUserActionById = () => {
	const queryClient = useQueryClient();

	const deleteCompanyUser = useMutation({
		mutationFn: (id: number) => CompanyService.deleteCompanyUser(id),
		mutationKey: ['deleteCompanyUser'],
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: [`get user`]
			});
			queryClient.invalidateQueries({
				queryKey: [`get users company`]
			});
		}
	});

	return {
		deleteCompanyUser
	};
};
