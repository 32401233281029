import { useEffect, useState } from 'react';
import useWebSocket from 'react-use-websocket';
import { chat_socket_url } from 'shared/api/config';
import { TChat } from 'shared/services/types';

export const useWebSocketClient = (onError?: (error: string) => void) => {
	const accessToken = localStorage.getItem('kira_token');
	const [chatHistory, setChatHistory] = useState<TChat.DialogMessage[]>([]);

	const { sendJsonMessage, lastMessage } = useWebSocket(
		`${chat_socket_url}/ws/chat?token=${accessToken}`,
		{
			onOpen: () => {
				console.log('Connected!');
			},
			onClose: e => {
				const error =
					e.code === 1006 ? 'Соединение потеряно' : 'Ошибка соединения';
				onError?.(e.reason || error);
				console.log('Disconnected!', e);
			},
			onMessage: e => {}
		}
	);

	useEffect(() => {
		if (lastMessage?.data) {
			const wsData = JSON.parse(lastMessage.data);

			setChatHistory(prev => [...prev, wsData.message]);
		}
		if (!chatHistory.length) {
			// sendFirstFile([]);
		}
	}, [lastMessage]);

	return { chatHistory, sendJsonMessage, lastMessage };
};
