import { useMutation, useQueryClient } from '@tanstack/react-query';
import ProjectService from 'shared/services/project.service';
import { TProject } from 'shared/services/types';

export const useProjectByIdActions = (id?: number) => {
	const queryClient = useQueryClient();

	const deleteProject = useMutation({
		mutationFn: () => ProjectService.deleteProject(id!),
		mutationKey: ['deleteProject', id],
		onSuccess: ({ data }) => {
			queryClient.invalidateQueries({ queryKey: [`get project list`] });
			return data;
		}
	});

	const changeProjectOwner = useMutation({
		mutationFn: (user_id: number) =>
			ProjectService.changeProjectOwner(id!, { user_id }),
		mutationKey: ['changeProjectOwner', id],
		onSuccess: ({ data }) => {
			queryClient.invalidateQueries({ queryKey: [`get project list`] });
			return data;
		}
	});

	const addComment = useMutation({
		mutationFn: (data: TProject.CommentRequest) =>
			ProjectService.addComment(id!, data),
		mutationKey: ['addComment', id],
		onSuccess: ({ data }) => {
			queryClient.invalidateQueries({ queryKey: [`get project`] });
			return data;
		}
	});

	const addProduct = useMutation({
		mutationFn: (data: TProject.AddProduct) =>
			ProjectService.addProduct(id!, data),
		mutationKey: ['addProduct', id]
	});

	const updateProductCount = useMutation({
		mutationFn: (data: {
			product_id: number;
			count: TProject.UpdateProductCount;
		}) => ProjectService.updateProductCount(id!, data.product_id, data.count),
		mutationKey: ['updateProduct', id],
		onSuccess: ({ data }) => {
			queryClient.invalidateQueries({ queryKey: [`get project`] });
			return data;
		}
	});

	const deleteProduct = useMutation({
		mutationFn: (product_id: number) =>
			ProjectService.deleteProduct(id!, product_id),
		mutationKey: ['deleteProduct', id],
		onSuccess: ({ data }) => {
			queryClient.invalidateQueries({ queryKey: [`get project`] });
		}
	});

	const updateProject = useMutation({
		mutationFn: (data: Omit<TProject.Update, 'id'>) =>
			ProjectService.updateProject(id!, data),
		mutationKey: ['deleteProduct', id],
		onSuccess: ({ data }) => {
			queryClient.invalidateQueries({ queryKey: [`get project list`] });
			return data;
		}
	});

	const addUserToProject = useMutation({
		mutationFn: (data: TProject.UserToProject) =>
			ProjectService.addUserToProject(id!, data),
		mutationKey: ['addUserToProject', id]
	});

	const deleteUserToProject = useMutation({
		mutationFn: (user_id: number) =>
			ProjectService.deleteUserFromProject(id!, user_id),
		mutationKey: ['deleteUserToProject', id]
	});

	return {
		deleteProject,
		changeProjectOwner,
		addComment,
		addProduct,
		updateProductCount,
		deleteProduct,
		updateProject,
		addUserToProject,
		deleteUserToProject
	};
};
