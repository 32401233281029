import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { PartnerService } from 'shared/services';
import { TPartner } from 'shared/services/types';

export const usePartnersActions = (id?: number, enabled?: boolean) => {
	const queryClient = useQueryClient();

	const getPartnerInfo = useQuery({
		queryFn: () => PartnerService.getInfo(),
		queryKey: ['partner info'],
		retry: 0,
		enabled: !enabled,
		select: ({ data }) => data
	});

	const onCreatePartner = useMutation({
		mutationFn: (data: TPartner.Create) => PartnerService.becomePartner(data),
		mutationKey: ['become partner'],
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: [`partner info`] });
		}
	});

	const updatePartner = useMutation({
		mutationFn: (data: TPartner.Update) => PartnerService.updatePartner(data),
		mutationKey: ['update partner'],
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: [`partner info`] });
		}
	});

	//TODO устаревшие
	const addFilePartner = useMutation({
		mutationFn: (data: TPartner.PartnerFile) => PartnerService.addFile(data),
		mutationKey: ['add file partner']
	});

	const addLogo = useMutation({
		mutationFn: (data: File) => PartnerService.addLogo(data),
		mutationKey: ['add logo']
	});

	const sendRequestPartner = useMutation({
		mutationFn: (data: TPartner.RequestToPartnerParams) =>
			PartnerService.sendRequestPartners(data),
		mutationKey: ['add request partner'],
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: [`partner info`] });
		}
	});

	const addCommentRequest = useMutation({
		mutationFn: (data: TPartner.RequestToPartnerComment) =>
			PartnerService.addCommentRequest(data),
		mutationKey: ['add comment partner'],
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: [`request partners list`] });
		}
	});

	const updateInfoRequest = useMutation({
		mutationFn: (data: TPartner.RequestToPartnerUpdate) =>
			PartnerService.updateInfoRequest(id!, data),
		mutationKey: ['updateInfoRequest'],
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: [`request partners list`] });
		}
	});

	const deleteInfoRequest = useMutation({
		mutationFn: (id: number) => PartnerService.deleteInfoRequest(id),
		mutationKey: ['deleteInfoRequest'],
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: [`request partners list`] });
		}
	});

	return {
		getPartnerInfo,
		onCreatePartner,
		addFilePartner,
		updatePartner,
		addLogo,
		sendRequestPartner,
		addCommentRequest,
		updateInfoRequest,
		deleteInfoRequest
	};
};
