import { useQuery, useQueryClient } from '@tanstack/react-query';
import ChatService from 'shared/services/chat.service';
import { TChat } from 'shared/services/types/';

export const useChat = (data?: TChat.DialogRequest) => {
	const queryClient = useQueryClient();

	const getMessages = useQuery({
		queryFn: () => ChatService.getMessages(data!),
		queryKey: ['getMessages', data],
		retry: 0,
		select: ({ data }) => data,
		enabled: !!data?.receiver_id
	});

	const getAllDialogs = useQuery({
		queryFn: () => ChatService.getAllDialogs(),
		queryKey: ['getAllDialogs'],
		retry: 0,
		select: ({ data }) => data,
		enabled: false
	});

	return {
		getMessages,
		getAllDialogs
	};
};

export const useChatMessages = (
	data?: TChat.DialogRequest,
	isAdmin?: boolean
) => {
	const result = useQuery({
		queryFn: () => ChatService.getMessages(data!),
		queryKey: ['getMessages', data],
		retry: 0,
		enabled: !!data?.receiver_id || !isAdmin,
		select: ({ data }) => data
	});

	return result;
};

export const useGetDialogs = (isAdmin?: boolean) => {
	const result = useQuery({
		queryFn: () => ChatService.getAllDialogs(),
		queryKey: ['getAllDialogs'],
		retry: 0,
		enabled: !!isAdmin,

		select: ({ data }) => data
	});

	return result;
};
