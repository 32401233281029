import { axiosInstance } from 'shared/api';
import { getSubscriptionUrl } from 'shared/api/config';
import { TSubscription } from './types/subscription.types';

const SubscriptionService = {
	async getSubscriptionTariff() {
		const response = await axiosInstance.get<TSubscription.TariffList>(
			getSubscriptionUrl(`/tariff`)
		);
		return response;
	},
	async getSubscriptionMe() {
		const response = await axiosInstance.get<TSubscription.TariffUser>(
			getSubscriptionUrl(`/user`)
		);
		return response;
	},

	async updateSubscription(data: TSubscription.Subscription) {
		const response = await axiosInstance.put<TSubscription.Subscription>(
			getSubscriptionUrl(`/user`),
			data
		);
		return response;
	},

	async requestSubscription(data: TSubscription.Subscription) {
		const response = await axiosInstance.post<TSubscription.Subscription>(
			getSubscriptionUrl(`/request`),
			data
		);
		return response;
	}
};

export default SubscriptionService;
