export const optionsINN = [
	{
		value: 'RUS',
		label: 'ИНН - Россия'
	},
	{
		value: 'KAZ',
		label: 'БИН - Казахстан'
	},
	{
		value: 'BLR',
		label: 'УНП - Беларусь'
	},
	{
		value: 'KGZ',
		label: 'ИНН - Кыргызстан'
	},
	{
		value: 'UZB',
		label: 'TIN - Узбекистан'
	},
	{
		value: 'UKR',
		label: 'ЄДРПОУ - Украина'
	}
];