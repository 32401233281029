import { useAuthActions } from 'features/auth/api';
import { FieldValues, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAlertMessage, useQueryParams } from 'shared/hooks';
import { REGEX_VERIFY } from 'shared/regex';
import { TAuth } from 'shared/services/types/auth.types';
import { FormWrapper } from 'shared/ui/form/form-wrapper';
import { Input } from 'shared/ui/form/input';
import './RecoveryPass.scss';

const RecoveryPass = () => {
	const params = useQueryParams();
	const tokenNew = params.token;

	const methods = useForm();
	const methodsRecovery = useForm();


	const navigate = useNavigate();
	const location = useLocation();
	const { addAlertMessage } = useAlertMessage();
	const { onRecoveryPassword, onSendRecoveryPassword } = useAuthActions();

	const emailRecoverySubmit = async (dataForm: FieldValues) => {
		console.log(dataForm);

		const forgot_url = `${window.location.origin}/`;

		const dataFirst = {
			forgot_url: `${forgot_url}recovery`,
			to_email: dataForm.email
		};
		try {
			await onSendRecoveryPassword.mutateAsync(dataFirst);
			addAlertMessage('Сообщение выслано на почту', 'info');
		} catch (error: any) {
			addAlertMessage(error, 'error');
		}
	};

	const passwordRecoverySubmit = async (dataForm: FieldValues) => {
		const tokenNew = params.token;
		console.log(dataForm);

		const dataSecond = {
			new_password1: dataForm.password1,
			new_password2: dataForm.password2
		} as TAuth.RecoveryPassword;

		try {
			await onRecoveryPassword.mutateAsync({
				data: dataSecond,
				token: tokenNew
			});
			addAlertMessage('Пароль успешно изменен', 'success');
			navigate(`/login/`);
		} catch (error: any) {
			addAlertMessage(error, 'error');
		}
	};

	return (
		<div className='auth-container'>
			{location.pathname === '/recovery' && !tokenNew ? (
				<div className='auth-block'>
					<div className='auth-block__header'>
						<h1>Введите email-адрес, привязанный к вашему аккаунту</h1>
						<p>На него будет выслана ссылка для восстановления пароля</p>
					</div>
					<FormWrapper methods={methods} onSubmit={emailRecoverySubmit}>
						<Input
							control={methods.control}
							name='email'
							label='Email'
							required
							error={methods.formState.errors}
							rules={{
								required: 'Обязательное поле для заполнения',
								pattern: {
									value: REGEX_VERIFY.EMAIL_REGEX,
									message: 'Укажите валидный email'
								}
							}}
						/>

						<button className='btn' type='submit'>
							Отправить
						</button>
					</FormWrapper>
				</div>
			) : (
				<div className='auth-block'>
					<div className='auth-block__header'>
						<h1>Введите новый пароль</h1>
					</div>
					<FormWrapper
						methods={methodsRecovery}
						onSubmit={passwordRecoverySubmit}
					>
						<Input
							control={methodsRecovery.control}
							name='password1'
							label='Пароль'
							isPassword
							required
							error={methodsRecovery.formState.errors}
							rules={{
								required: 'Обязательное поле для заполнения'
							}}
						/>

						<Input
							control={methodsRecovery.control}
							name='password2'
							label='Повторите пароль'
							isPassword
							required
							error={methodsRecovery.formState.errors}
							rules={{
								required: 'Обязательное поле для заполнения',
								validate: value => {
									const { password1 } = methodsRecovery.getValues();
									return value === password1 || 'Пароли не совпадают';
								}
							}}
						/>

						<button className='btn' type='submit'>
							Отправить
						</button>
					</FormWrapper>
				</div>
			)}
		</div>
	);
};

export default RecoveryPass;
