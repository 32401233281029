import Cookies from 'js-cookie';
import {
	FC,
	PropsWithChildren,
	createContext,
	useEffect,
	useState
} from 'react';

interface ContextProps {
	authState: boolean;
	currentCurrency: any;
	onChangeCurrencyApp: React.Dispatch<any>;
	setCurrentCurrency: React.Dispatch<any>;
}

const defaultValue = {
	authState: false,
	currentCurrency: 'en',
	passwordRecovery: () => {},
	passwordChange: () => {},
	onChangeCurrencyApp: () => {},
	setCurrentCurrency: () => {}
} as ContextProps;

const CommonContext = createContext<ContextProps>(defaultValue);

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
	const token = localStorage.getItem('kira_token');

	const KIRA_CURRENCY_DEFAULT = (Cookies.get('kira_currency') ||
		'{}') as string;
	const KIRA_CURRENCY = JSON.parse(KIRA_CURRENCY_DEFAULT);
	const [currentCurrency, setCurrentCurrency] = useState(KIRA_CURRENCY);
	const [authState, setAuthState] = useState(!!token);

	useEffect(() => {
		setCurrentCurrency(KIRA_CURRENCY);
	}, [KIRA_CURRENCY_DEFAULT]);

	const updateAuthState = () => {
		const token = localStorage.getItem('kira_token');

		setAuthState(!!token);
	};

	useEffect(() => {
		window.addEventListener('storage', updateAuthState);
		return () => {
			window.removeEventListener('storage', updateAuthState);
		};
	}, []);

	const onChangeCurrencyApp = (data: string) => {
		setCurrentCurrency(data);
	};

	return (
		<CommonContext.Provider
			value={{
				authState,
				currentCurrency,
				onChangeCurrencyApp,
				setCurrentCurrency
			}}
		>
			{children}
		</CommonContext.Provider>
	);
};

export default CommonContext;
