import { axiosInstance } from 'shared/api';
import { getCompanyUrl } from 'shared/api/config';
import { TAuth } from 'shared/services/types/auth.types';
import { TCompany } from './types/company.types';

const CompanyService = {
	async getCompany(id?: number) {
		const response = await axiosInstance.get<TCompany.Response>(
			getCompanyUrl(`/${id}`)
		);
		return response;
	},

	async createCompany(data: TCompany.Data) {
		const response = await axiosInstance.post<TCompany.Response>(
			getCompanyUrl(``),
			data
		);
		return response;
	},

	async updatePartCompany(data: TCompany.DataUpdate) {
		const response = await axiosInstance.patch<TCompany.Response>(
			getCompanyUrl(``),
			data
		);
		return response;
	},

	async getInfoByInn(data: TCompany.Request) {
		const response = await axiosInstance.get<TCompany.InnInfo>(
			getCompanyUrl(`/get_info/${data.inn}`),
			{
				params: {
					country: data.country
				}
			}
		);
		return response;
	},

	async getTypesCompany() {
		const response = await axiosInstance.get<TCompany.CompanyType[]>(
			getCompanyUrl(`/types`)
		);
		return response;
	},

	async getUsersCompany() {
		const response = await axiosInstance.get<TCompany.EmployeesCompany[]>(
			getCompanyUrl(`/employees`)
		);
		return response;
	},

	async sendInviteToCompany(data: TCompany.InviteCompany) {
		const response = await axiosInstance.post<TCompany.Response>(
			getCompanyUrl(`/invite`),
			data
		);
		return response;
	},

	async confirmInviteToCompany(data: TAuth.Register, token: string) {
		const response = await axiosInstance.post<TCompany.Response>(
			getCompanyUrl(`/company/invite/confirm`),
			data,
			{
				params: { token }
			}
		);
		return response;
	},

	async deleteCompanyUser(id: number) {
		const response = await axiosInstance.delete<string>(
			getCompanyUrl(`/remove/${id}`)
		);
		return response;
	},

	async getIncreaseCompany(data: TCompany.IncreaseCompany) {
		const response = await axiosInstance.post<TCompany.IncreaseResponse>(
			getCompanyUrl(`/increase_max`),
			data
		);
		return response;
	},

	// СТАРЫЕ МЕТОДЫ
	async reportCompany(inn: string) {
		const response = await axiosInstance.get<string>(
			getCompanyUrl(`/report/`),
			{
				params: inn
			}
		);
		return response;
	}
};

export default CompanyService;
