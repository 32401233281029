import 'assets/input.scss';
import clsx from 'clsx';
import { PartnerPermissionModal } from 'entities/partners/permission-modal';
import { useCompanyActions } from 'features/company/api/useCompanyActions';
import { useGetCompany } from 'features/company/api/useGetCompany';
import { useUsersCompany } from 'features/company/api/useUserCompany';
import EmployeeComponent from 'features/profile/ui/employee-component';
import { useGetTariff } from 'features/tariff/api/useGetTariff';
import { useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useAlertMessage, useOutsideClick, useRoleUser } from 'shared/hooks';
import { REGEX_VERIFY } from 'shared/regex';
import { FormWrapper } from 'shared/ui/form/form-wrapper';
import { Input } from 'shared/ui/form/input';
import { tokenParse } from 'shared/utils';
import './ProfileEmployees.scss';

const partnerListAccess = [`Корпоративный`, `Корпоративный партнёр`];
const ProfileEmployees = () => {
	const { is_boss } = tokenParse();
	const methods = useForm();
	const methodsTwo = useForm();

	const { isOwnerCompany } = useRoleUser();

	const { addAlertMessage } = useAlertMessage();
	const { tariffName, isPending } = useGetTariff();
	const { sendInviteCompany, addIncreaseCompany } = useCompanyActions();
	const { companyData } = useGetCompany();
	const { companyUsersData, error, isFetching } = useUsersCompany();

	const [usersMaxAmount, setUsersMaxAmount] = useState<number>(1);
	const [usersAmount, setUsersAmount] = useState(1);
	const [isOpen, setIsOpen] = useState(false);
	const [isOpenModal, setIsOpenModal] = useState(false);
	const [isOpenAdd, setIsOpenAdd] = useState(false);

	const [showOverlay, setShowOverlay] = useState(false);
	const [owner, setOwner] = useState(false);
	const { ref } = useOutsideClick(() => setIsOpenAdd(false));

	const [newUserMessage, setNewUserMessage] = useState<string>();
	const [newErrorMessage, setErrorMessage] = useState<string | undefined>();

	const checkSub = async () => {
		if (!partnerListAccess.includes(tariffName) && isOwnerCompany) {
			setShowOverlay(true);
			setIsOpenModal(true);
		} else {
			setShowOverlay(!companyData || !isOwnerCompany);
			setOwner(true);
		}
	};

	const onCloseModal = () => {
		setIsOpenModal(false);
	};

	useEffect(() => {
		if (error && !isFetching) {
			addAlertMessage(error, 'error');
		}
	}, [!!error, isFetching]);

	useEffect(() => {
		if (companyUsersData) {
			setUsersAmount(companyUsersData.length);
		}
	}, [companyUsersData]);

	const sendInvite = async (values: FieldValues) => {
		const url = window.location.origin;

		const data = {
			invite_url: `${url}/register/${values.email}/${encodeURIComponent(
				values.fio || ''
			)}`,
			email: values.email
		};
		try {
			await sendInviteCompany.mutateAsync(data);

			addAlertMessage(
				'Вашему сотруднику отправлено приглашение на указанную электронную почту. Если письма нет, пожалуйста, проверьте папку Спам.',
				'info'
			);
			setIsOpenAdd(false);

			setErrorMessage('');
		} catch (error: any) {
			addAlertMessage(error, 'error');
		}
	};

	const increaseEmployeesAmmount = async (values: FieldValues) => {
		try {
			const data = {
				new_max: Number(usersMaxAmount) + Number(values.count),
				price: 2000
			};

			await addIncreaseCompany.mutateAsync(data);
			addAlertMessage(`Мы получили ваш запрос и скоро ответим вам`, 'info');
		} catch (error: any) {
			addAlertMessage(error, 'error');
		}
	};

	useEffect(() => {
		if (!isPending) checkSub();
	}, [tariffName, isPending, companyData]);

	return (
		<div className='employees-container'>
			<div className={clsx(`employees-header`, !owner && 'employee')}>
				<div>
					<h1>
						Активных сотрудников: {usersAmount || 0} / {companyData?.max_users}
					</h1>
					<p className={isOpen ? 'info-close' : ''}>
						В корпоративном тарифе есть возможность предоставлять доступ к
						порталу <br /> своим сотрудникам. Если у вас закончились свободные
						места для <br /> сотрудников, то вы можете воспользоваться
						возможностью расширить штат.
					</p>
				</div>
				{is_boss && (
					<div className={`employees-header-button ${isOpen ? 'hidden' : ''}`}>
						<button
							className='btn'
							disabled={!owner}
							onClick={() => setIsOpen(!isOpen)}
						>
							Расширить штат
						</button>
					</div>
				)}
			</div>
			<div
				className={`ammount-dropdown ${
					isOpen ? 'ammount-open' : 'ammount-close'
				}`}
			>
				<FormWrapper methods={methods} onSubmit={increaseEmployeesAmmount}>
					<Input
						control={methods.control}
						name='count'
						label='Введите число сотрудников, которое хотите добавить'
						required
						type='number'
						error={methods.formState.errors}
						rules={{ required: 'Обязательное поле для заполнения' }}
					/>

					<div className={'ammount-dropdown-wrapper-btn'}>
						<button className='btn' type='submit'>
							Отправить запрос
						</button>

						<button
							className='btn-back'
							type='button'
							onClick={() => setIsOpen(!isOpen)}
						>
							назад
						</button>
					</div>
				</FormWrapper>
			</div>

			<div className={'employees-list'}>
				<div className='employees-list-header'>
					<h1>{is_boss ? 'Управление сотрудниками' : 'Список сотрудников'}</h1>
					<p style={{ display: is_boss ? 'block' : 'none' }}>
						Здесь вы можете редактировать список сотрудников
					</p>
				</div>
				{companyUsersData?.length ? (
					companyUsersData?.map(i => <EmployeeComponent key={i.id} data={i} />)
				) : (
					<p className='zero-employees-ammount'>
						На данный момент у вас нет добавленных сотрудников
					</p>
				)}
				<div className={`modal modal-complaint ${isOpenAdd ? 'show' : 'hide'}`}>
					<div className='modal__window' ref={ref}>
						<div className='modal__header'>
							<h3>Выслать доступ сотруднику</h3>
							<button
								className='modal__close'
								onClick={() => setIsOpenAdd(!isOpenAdd)}
							>
								<svg
									width='32'
									height='32'
									viewBox='0 0 32 32'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										d='M19.75 12.25L12.25 19.75M12.25 12.25L19.7499 19.75'
										stroke='#8A7DE7'
										strokeWidth='1.5'
										strokeLinecap='round'
									/>
									<path
										d='M31 16C31 23.0711 31 26.6066 28.8033 28.8033C26.6066 31 23.0711 31 16 31C8.92893 31 5.3934 31 3.1967 28.8033C1 26.6066 1 23.0711 1 16C1 8.92893 1 5.3934 3.1967 3.1967C5.3934 1 8.92893 1 16 1C23.0711 1 26.6066 1 28.8033 3.1967C30.2639 4.65731 30.7533 6.70983 30.9173 10'
										stroke='#8A7DE7'
										strokeWidth='1.5'
										strokeLinecap='round'
									/>
								</svg>
							</button>
						</div>
						<div className='modal__form'>
							<p
								className={
									usersAmount &&
									usersMaxAmount &&
									usersAmount !== usersMaxAmount
										? `new-employee-info`
										: 'new-employee-info-close'
								}
							>
								После отправки придет реферальная ссылка на почту сотруднику,
								необходимо перейти по ней и ввести ключ
							</p>
							{newUserMessage && (
								<p className='new-employee-message'>{newUserMessage}</p>
							)}{' '}
							{newErrorMessage && (
								<p className='new-employee-message-error'>{newErrorMessage}</p>
							)}
							<FormWrapper methods={methodsTwo} onSubmit={sendInvite}>
								<Input
									control={methodsTwo.control}
									name='fio'
									label='ФИО'
									required
									error={methodsTwo.formState.errors}
									rules={{ required: 'Обязательное поле для заполнения' }}
								/>
								<Input
									control={methodsTwo.control}
									name='email'
									label='Email'
									required
									error={methodsTwo.formState.errors}
									placeholder='Введите почту сотрудника'
									rules={{
										required: 'Обязательное поле для заполнения',
										pattern: {
											value: REGEX_VERIFY.EMAIL_REGEX,
											message: 'Укажите валидный email'
										}
									}}
								/>

								<button className='btn' type='submit'>
									Отправить ключ
								</button>
							</FormWrapper>
						</div>
					</div>
				</div>
				{is_boss &&
					(companyUsersData?.length || 0) < (companyData?.max_users || 0) && (
						<button
							className='btn'
							disabled={!owner}
							onClick={() => setIsOpenAdd(!isOpenAdd)}
						>
							Добавить сотрудника
						</button>
					)}
			</div>
			{!!showOverlay && (
				<div className='profile-overlay'>
					<div className='profile-overlay-content'>
						<p>
							{!isOwnerCompany ? (
								<>
									Для совместной работы с сотрудниками нужно быть владельцем
									компании{' '}
								</>
							) : (
								<>
									Для совместной работы с сотрудниками смените тариф на
									подходящий в разделе{' '}
									<Link to='/account/tariffs'>“Тарифы и оплата”</Link>
								</>
							)}
						</p>
					</div>
				</div>
			)}

			<PartnerPermissionModal
				isOpen={isOpenModal}
				onClose={onCloseModal}
				title='Высылать доступ сотрудникам можно на:'
				data={partnerListAccess}
			/>
		</div>
	);
};

export default ProfileEmployees;
