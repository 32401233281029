import { useQuery } from '@tanstack/react-query';
import { ProductService } from 'shared/services';

export const useManufactureList = () => {
	const manufactureList = useQuery({
		queryFn: () => ProductService.getManufactureList(),
		queryKey: ['request partners list'],
		select: ({ data }) => data,
		retry: 0
	});

	return manufactureList;
};
