import { axiosInstance } from 'shared/api';
import { getProductUrl } from 'shared/api/config';
import { fetchParams } from 'shared/utils';
import { TProduct } from './types/products.types';

const ProductService = {
	async getList(data?: TProduct.ProductFilter) {
		const response = await axiosInstance.get<TProduct.ProductResponse>(
			getProductUrl(``),
			{ params: fetchParams(data) }
		);
		return response;
	},

	async getManufactureList() {
		const response = await axiosInstance.get<TProduct.ManufactureProduct[]>(
			getProductUrl(`/manufacturer`)
		);
		return response;
	}
};

export default ProductService;
