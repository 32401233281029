import axios, { AxiosError, InternalAxiosRequestConfig } from 'axios';

import { removeTokensStorage } from 'shared/services/auth/auth.helpers';
import AuthService from 'shared/services/auth/auth.service';
import { backend_url } from './config';
import { errorCatch } from './helpers';

export const axiosClassic = axios.create({
	withCredentials: true,
	baseURL: backend_url
});

export const axiosInstance = axios.create({
	withCredentials: true,
	baseURL: backend_url
});

const interceptorsRequestSuccess = (
	config: InternalAxiosRequestConfig<any>
) => {
	const isLogoutRoute = config.url === '/user/logout';

	const accessToken = localStorage.getItem('kira_token');

	if (config.headers && !isLogoutRoute) {
		config.headers.Authorization = `Bearer ${accessToken}`;
	}

	return config;
};

axiosInstance.interceptors.request.use(interceptorsRequestSuccess);

const interceptorsResponseError = async (error: AxiosError<any>) => {
	const originalRequest = { ...error.config, _isRetry: false };
	const isRefreshError = error?.config?.url === '/user/token/refresh';
	if (
		!isRefreshError &&
		([401].includes(error.response?.status || 0) ||
			// TODO error messages
			errorCatch(error) === 'jwt expired' ||
			errorCatch(error) === 'jwt must be provided') &&
		error.config &&
		!originalRequest._isRetry
	) {
		originalRequest._isRetry = true;

		try {
			await AuthService.refresh();

			return axiosInstance.request(originalRequest);
		} catch (error) {
			if (errorCatch(error) === 'jwt expired') {
				removeTokensStorage();
			}
		}
	}

	throw error;
};

axiosInstance.interceptors.response.use(async config => {
	return config;
}, interceptorsResponseError);

export default axiosInstance;
