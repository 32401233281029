type ReviewsCount = Record<number, number>;

/**
 * Вычисляет среднюю оценку на основе количества отзывов по каждой оценке.
 *
 * @param {ReviewsCount} reviews_count - Объект, содержащий количество отзывов для каждой оценки.
 * Ключи - это строки, представляющие оценки (например, "1", "2", "3", "4", "5"),
 * а значения - это количество отзывов для каждой оценки.
 *
 * @returns {number} - Средняя оценка. Если отзывов нет, возвращает 0.
 */

export const calculateAverageRating = (
	reviews_count: ReviewsCount
): string | number => {
	let totalScore = 0;
	let totalReviews = 0;

	for (const [rating, count] of Object.entries(reviews_count)) {
		totalScore += Number(rating) * count; // Умножаем оценку на количество отзывов
		totalReviews += count; // Суммируем общее количество отзывов
	}

	// Если нет отзывов, возвращаем 0
	if (totalReviews === 0) {
		return 0;
	}

	// Возвращаем среднюю оценку
	return (totalScore / totalReviews).toFixed(1);
};
