import { useQuery } from '@tanstack/react-query';
import { useGetUser } from 'features/profile/api';
import CompanyService from 'shared/services/company.service';

export const useGetCompany = (id?: number) => {
	const { userData } = useGetUser();
	const companyId = id || userData?.company_id;

	const { isPending, isError, isSuccess, data, error } = useQuery({
		queryFn: () => CompanyService.getCompany(companyId),
		queryKey: ['get company', companyId],
		enabled: !!companyId,
		select: ({ data }) => data
	});

	return { isPending, isError, isSuccess, error, companyData: data };
};
