import clsx from 'clsx';
import { format, parseISO } from 'date-fns';
import { usePartnersActions } from 'features/partners/api/usePartnersActions';
import { useGetCountries } from 'features/utils/api';
import { FC } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useAlertMessage } from 'shared/hooks';
import { TSupplier } from 'shared/services/types';
import { FormWrapper } from 'shared/ui/form/form-wrapper';
import { Input } from 'shared/ui/form/input';

interface IProps {
	data: TSupplier.RequestDataMini;
	onCloseRequest: (data: any) => void;
	onDealTookPlace: (data: any) => void;
}

export const CardRequest: FC<IProps> = ({
	data,
	onCloseRequest,
	onDealTookPlace
}) => {
	const { data: countries } = useGetCountries();
	const request_id = data.id;
	const { addCommentRequest } = usePartnersActions(request_id, true);
	const { addAlertMessage } = useAlertMessage();
	const parsedDate = parseISO(data?.created_at);
	const formattedDate = format(parsedDate, 'dd.MM.yyyy');
	const methods = useForm();

	const flagImg = countries?.find(
		item => item.id === data?.supplier?.countries?.[0]?.id
	)?.flag;

	const onSubmitComment = async (data: FieldValues) => {
		const { text } = data;
		if (!text) return;
		const res = {
			text,
			request_id
		};
		try {
			await addCommentRequest.mutateAsync(res);
			addAlertMessage('Заметка добавлена', 'success');
		} catch (error: any) {
			addAlertMessage(error, 'error');
		}
	};

	return (
		<div className='request-supplier'>
			<div className='request-supplier__theme'>
				<p>Тема запроса: «{data?.theme}»</p>
				<button onClick={() => onCloseRequest(data)}>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='32'
						height='32'
						viewBox='0 0 32 32'
						fill='none'
					>
						<path
							d='M19.75 12.25L12.25 19.75M12.25 12.25L19.7499 19.75'
							stroke='#8A7DE7'
							strokeWidth='1.5'
							strokeLinecap='round'
						/>
						<path
							d='M31 16C31 23.0711 31 26.6066 28.8033 28.8033C26.6066 31 23.0711 31 16 31C8.92893 31 5.3934 31 3.1967 28.8033C1 26.6066 1 23.0711 1 16C1 8.92893 1 5.3934 3.1967 3.1967C5.3934 1 8.92893 1 16 1C23.0711 1 26.6066 1 28.8033 3.1967C30.2639 4.65731 30.7533 6.70983 30.9173 10'
							stroke='#8A7DE7'
							strokeWidth='1.5'
							strokeLinecap='round'
						/>
					</svg>
				</button>
			</div>
			<div className='request-supplier__info'>
				<div className='request-supplier__partner'>
					<img
						src={flagImg}
						alt='флаг страны'
						className={'request-supplier__partner--img'}
					/>
					Партнер: {data?.supplier?.name}
				</div>
				<div className='request-supplier__date'>
					Дата отправки: {formattedDate}
				</div>
			</div>
			<div className='request-supplier__note'>
				<div className='inputs-row'>
					<FormWrapper
						className='input-block'
						methods={methods}
						onSubmit={onSubmitComment}
					>
						<Input
							control={methods.control}
							name='text'
							label='Введите свои заметки'
							placeholder='Введите свои заметки'
							required
							// disabled={data?.archived}
							rules={{ required: 'Обязательное поле для заполнения' }}
						/>
					</FormWrapper>
					<button
						// className={clsx('btn', data?.archived && 'btn-archive')}
						className={clsx('btn')}
						onClick={() => onDealTookPlace(data)}
					>
						{/* {data?.archived ? 'Разархивировать' : '	Сделка состоялась?'} */}
						{'Сделка состоялась?'}
					</button>
				</div>
			</div>
		</div>
	);
};
