import { ReviewStats } from 'entities/partners/reviews-stats';
import { ReviewBlock } from 'features/partners/ui/review-block';
import { ReviewsSort } from 'features/partners/ui/reviews-sort';
import { useGetReviews } from 'features/reviews/api/useReviewsList';
import { calculateAverageRating } from 'features/reviews/helper';
import { FC, useEffect, useState } from 'react';
import { TSupplier } from 'shared/services/types';

import { DownloadMore } from 'shared/ui/form/download-more';

interface IProps {
	data?: TSupplier.DataDetail;
	supplier_type?: TSupplier.SupplerType;
}

const defaultPage = {
	page: 1,
	page_size: 20
};

const defaultRating = {
	5: 0,
	4: 0,
	3: 0,
	2: 0,
	1: 0
};
export const ReviewList: FC<IProps> = ({ data }) => {
	const [reviews, setReviews] = useState<TSupplier.Review[]>([]);
	const [filter, setFilter] = useState<TSupplier.ReviewsFilter>();
	const [rating, setRating] = useState(defaultRating);
	const [sort, setSort] = useState('created_at');

	const { reviewsList } = useGetReviews(filter);

	useEffect(() => {
		if (data)
			setFilter({
				...defaultPage,
				supplier_id: data.id,
				order_by: sort
			});
	}, [data, sort]);

	useEffect(() => {
		if (reviewsList.reviewsCount) {
			setRating(prev => ({ ...prev, ...reviewsList.reviewsCount }));
		}
	}, [!!reviewsList.reviewsCount]);

	useEffect(() => {
		if (reviewsList.data?.results) {
			setReviews(reviewsList.data.results);
		}
	}, [reviewsList.data]);

	const onSorting = (nameSort: string) => {
		setSort(nameSort);
	};

	const isShowMore =
		(reviewsList.data?.count || 0) > (reviewsList.data?.results?.length || 0);

	return (
		<div className='partner-reviews'>
			<div className='container'>
				<h2>Отзывы</h2>
				<div style={{ marginBottom: '30px' }}>
					<ReviewsSort onSorting={onSorting} />
				</div>
				<div className='partner-reviews__row'>
					{!reviews.length || !data ? (
						<h3>Отзывы отсутствуют</h3>
					) : (
						<>
							<div className='partner-reviews__block'>
								<div className='partner-review-block'>
									{reviews.map(item => (
										<ReviewBlock
											key={item.id}
											data={item}
											supplierId={data.id}
										/>
									))}
								</div>
								{isShowMore && (
									<DownloadMore onClick={reviewsList.fetchNextPage} />
								)}
							</div>
							<ReviewStats
								reviewData={{
									review: calculateAverageRating(rating),
									reviews_count: reviewsList.data.count,
									rating: rating
								}}
							/>
						</>
					)}
				</div>
			</div>
		</div>
	);
};
