import { useQuery, useQueryClient } from '@tanstack/react-query';
import { SupplierService } from 'shared/services';
import { TSupplier } from 'shared/services/types';

export const useRequestToSupplier = (data?: TSupplier.GetListRequests) => {
	const queryClient = useQueryClient();
	return useQuery({
		queryFn: () => SupplierService.getRequestPartnersList(data),
		queryKey: ['get supplier', data],
		select: ({ data }) => data,
		retry: 0,
	});
};
