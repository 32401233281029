import { format, isThisYear, isToday, isYesterday, subDays } from 'date-fns';
import { fromZonedTime } from 'date-fns-tz';
import { ru } from 'date-fns/locale';

export const getLocaleTime = (
	timestamp?: string | null,
	nowTime?: boolean,
	formatDate?: string
) => {
	if (!timestamp) return null;

	const date = nowTime ? new Date(timestamp) : fromZonedTime(timestamp, 'UTC');

	if (formatDate) {
		return format(date, 'HH:mm');
	}

	// Проверка на сегодняшний день
	if (isToday(date)) {
		return format(date, 'HH:mm');
	}

	// Проверка на вчерашний день
	if (isYesterday(date)) {
		return `Вчера ${format(date, 'HH:mm')}`;
	}

	// Проверка на позавчерашний день
	if (
		format(date, 'yyyy-MM-dd') === format(subDays(new Date(), 2), 'yyyy-MM-dd')
	) {
		return `Позавчера ${format(date, 'HH:mm')}`;
	}

	// Проверка на этот год
	if (isThisYear(date)) {
		return format(date, 'dd MMMM HH:mm', { locale: ru });
	}

	// Для других годов
	return format(date, 'dd MMMM yy HH:mm', { locale: ru });
};

export const createUTCDateNow = () => {
	const now = new Date();

	const nowUtc = new Date().toISOString(); // Создаем ISO строку в формате UTC

	// Если нужно дополнительное форматирование, можно сделать так:
	const formattedUtc = format(new Date(nowUtc), "yyyy-MM-dd'T'HH:mm:ss.SSS");

	return formattedUtc;
};
