import clsx from 'clsx';
import { format, parseISO } from 'date-fns';
import { FC, useState } from 'react';
import { useOutsideClick } from 'shared/hooks';
import { TPrice } from 'shared/services/types';

interface IProps {
	data: TPrice.HistorySource;
	onClickEdit: (data: TPrice.HistorySource) => void;
	onDeleteItem: (data: TPrice.HistorySource) => void;
	error?: string;
}

export const PriceItem: FC<IProps> = ({
	data,
	onClickEdit,
	onDeleteItem,
	error
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const parsedDate = parseISO(data!.created_at);
	const formattedDate = format(parsedDate, 'dd.MM.yyyy');
	const { ref } = useOutsideClick(() => setIsOpen(false));

	return (
		<>
			<div className={clsx('source-item', error && 'error')}>
				<div className='source-item__value'>
					<a
						target='_blank'
						href={data?.file}
						download
						className='source-item__url'
					>
						{data?.file}
					</a>
					<div className='source-item__status'>
						Дата создания: {formattedDate}
					</div>
				</div>

				<div className='data-source-item__action' ref={ref}>
					<button onClick={() => setIsOpen(true)}>
						<svg
							width='39'
							height='39'
							viewBox='0 0 39 39'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								d='M19.5032 27.6251C21.2981 27.6244 22.7538 29.0788 22.7545 30.8738C22.7553 32.6687 21.3008 34.1244 19.5059 34.1251C17.7109 34.1259 16.2553 32.6714 16.2545 30.8765C16.2538 29.0815 17.7082 27.6259 19.5032 27.6251Z'
								fill='#333333'
							/>
							<path
								d='M19.4984 16.2501C21.2933 16.2494 22.749 17.7038 22.7498 19.4988C22.7505 21.2937 21.296 22.7494 19.5011 22.7501C17.7062 22.7509 16.2505 21.2964 16.2498 19.5015C16.249 17.7065 17.7035 16.2509 19.4984 16.2501Z'
								fill='#333333'
							/>
							<path
								d='M19.4937 4.87511C21.2886 4.87436 22.7443 6.32883 22.745 8.12376C22.7458 9.91868 21.2913 11.3744 19.4964 11.3751C17.7015 11.3759 16.2458 9.92139 16.245 8.12646C16.2443 6.33154 17.6988 4.87586 19.4937 4.87511Z'
								fill='#333333'
							/>
						</svg>
					</button>
					{isOpen && (
						<div className='action-menu'>
							<button
								onClick={() => {
									setIsOpen(false);
								}}
							>
								&#10006;
							</button>
							<button onClick={() => onDeleteItem(data)}>Удалить</button>
						</div>
					)}
				</div>
			</div>
			{error && (
				<p className='error-message'>
					К сожалению, вы не можете использовать этот источник!
				</p>
			)}
		</>
	);
};
