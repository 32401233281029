import clsx from 'clsx';
import { FC } from 'react';

interface IProps {
	onClick?: () => void;
	disabled?: boolean;
}

export const DownloadMore: FC<IProps> = ({ onClick, disabled }) => {
	return (
		<div
			className={clsx('next-previous-page', disabled && 'disabled')}
			onClick={() => {
				if (!disabled) {
					onClick?.();
				}
			}}
		>
			<button disabled={disabled} className={clsx(disabled && 'disabled')}>
				Загрузить еще
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='15'
					height='15'
					viewBox='0 0 13 13'
					fill='none'
					preserveAspectRatio='none'
				>
					<g clipPath='url(#clip0_221_6026)'>
						<path
							d='M8.125 6.50001L6.5 6.50001M6.5 6.50001L4.875 6.50001M6.5 6.50001L6.5 4.875M6.5 6.50001L6.5 8.125'
							stroke='#333333'
							strokeLinecap='round'
						/>
						<path
							d='M3.79183 1.80815C4.58855 1.34727 5.51355 1.0835 6.50016 1.0835C9.49171 1.0835 11.9168 3.50862 11.9168 6.50016C11.9168 9.49171 9.49171 11.9168 6.50016 11.9168C3.50862 11.9168 1.0835 9.49171 1.0835 6.50016C1.0835 5.51355 1.34727 4.58855 1.80815 3.79183'
							stroke='#333333'
							strokeLinecap='round'
						/>
					</g>
					<defs>
						<clipPath id='clip0_221_6026'>
							<rect width='13' height='13' fill='white' />
						</clipPath>
					</defs>
				</svg>
			</button>
		</div>
	);
};
