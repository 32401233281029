export const optionsNdsSelect = [
	{ value: 'yes', label: 'Работаем с НДС' },
	{ value: 'no', label: 'Не работаем с НДС' }
];

export const optionsDocument = [
	{ value: 'yes', label: 'С таможней' },
	{ value: 'no', label: 'Без таможни' },
	{ value: 'all', label: 'С таможней / Без таможни' }
];

export const optionsDaysWork = [
	{ value: 1, label: 'Понедельник' },
	{ value: 2, label: 'Вторник' },
	{ value: 3, label: 'Среда' },
	{ value: 4, label: 'Четверг' },
	{ value: 5, label: 'Пятница' },
	{ value: 6, label: 'Суббота' },
	{ value: 7, label: 'Воскресенье' }
];
