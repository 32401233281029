import { useAuthActions } from 'features/auth/api';
import { FieldValues, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { useAlertMessage } from 'shared/hooks';
import { TAuth } from 'shared/services/types/auth.types';
import { FormWrapper } from 'shared/ui/form/form-wrapper';
import { Input } from 'shared/ui/form/input';
import './LoginForm.scss';

const LoginForm = () => {
	const methods = useForm<TAuth.Login>({ mode: 'onBlur' });
	const navigate = useNavigate();
	const { onLogin } = useAuthActions();
	const { addAlertMessage } = useAlertMessage();

	const loginSubmit = async (dataForm: FieldValues) => {
		const data = {
			...dataForm
		} as TAuth.Login;

		try {
			await onLogin.mutateAsync(data);
			navigate('/');
		} catch (error: any) {
			addAlertMessage(error, 'error');
		}
	};

	return (
		<div className='auth-container'>
			<div className='auth-block'>
				<div className='auth-block__header'>
					<h1>Вход в личный кабинет</h1>
					<p>При регистрации придумайте пароль</p>
				</div>
				<FormWrapper methods={methods} onSubmit={loginSubmit}>
					<Input
						placeholder='Email'
						control={methods.control}
						label='Email'
						required
						name='username'
						error={methods.formState.errors}
						rules={{ required: 'Обязательное поле для заполнения' }}
					/>
					<Input
						placeholder='Пароль'
						control={methods.control}
						label='Пароль'
						required
						isPassword
						name='password'
						error={methods.formState.errors}
						rules={{ required: 'Обязательное поле для заполнения' }}
					/>

					<div className='form-tooltip'>
						<Link className='reset-password' to={'/recovery'}>
							Восстановить
						</Link>

						<Link className='reset-password' to={'/register/'}>
							Зарегистрироваться?
						</Link>
					</div>

					<button className='btn' type='submit'>
						Вход
					</button>
				</FormWrapper>

				{/* //TODO временно убран */}
				{/* <AuthServices /> */}
			</div>
		</div>
	);
};
export default LoginForm;
