import { axiosInstance } from 'shared/api';
import { getProjectUrl } from 'shared/api/config';
import { TProject } from './types/project.types';

const ProjectService = {
	// ПРОЕКТы

	async create(data: TProject.Create) {
		const response = await axiosInstance.post<TProject.Data>(
			getProjectUrl(``),
			data
		);
		return response;
	},
	async getList(data?: TProject.Filter) {
		const response = await axiosInstance.get<TProject.ProjectResponse>(
			getProjectUrl(``),
			{ params: data }
		);
		return response;
	},

	async getProjectInfo(id: number, data?: TProject.Info) {
		const response = await axiosInstance.get<TProject.Data>(
			getProjectUrl(`/${id}`),
			{
				params: data
			}
		);
		return response;
	},
	async updateProject(id: number, data: TProject.Update) {
		const response = await axiosInstance.patch<string>(
			getProjectUrl(`/${id}`),
			data
		);
		return response;
	},

	async deleteProject(id: number) {
		const response = await axiosInstance.delete<string>(
			getProjectUrl(`/${id}`)
		);
		return response;
	},

	// ТОВАРЫ ПРОЕКТОВ

	//TODO пока не используется, на бэке нет
	async pickPartner(products?: string[]) {
		const response = await axiosInstance.get<string>(
			getProjectUrl(`/pick/partners`),
			{ params: { products } }
		);
		return response;
	},

	async findProjectByProduct(id?: number) {
		const response = await axiosInstance.get<number[]>(
			getProjectUrl(`/find/${id}`)
		);
		return response;
	},

	async addProduct(id: number, data: TProject.AddProduct) {
		const response = await axiosInstance.post<string>(
			getProjectUrl(`/${id}/product`),
			data
		);
		return response;
	},

	async updateProductCount(
		id: number,
		product_id: number,
		data: TProject.UpdateProductCount
	) {
		const response = await axiosInstance.put<string>(
			getProjectUrl(`/${id}/product/${product_id}`),
			data
		);
		return response;
	},

	async deleteProduct(id: number, product_id: number) {
		const response = await axiosInstance.delete<string>(
			getProjectUrl(`/${id}/product/${product_id}`)
		);
		return response;
	},

	async addUserToProject(id: number, data: TProject.UserToProject) {
		const response = await axiosInstance.post<string>(
			getProjectUrl(`/${id}/user`),
			data
		);
		return response;
	},

	async deleteUserFromProject(id: number, employee_id: number) {
		const response = await axiosInstance.delete<string>(
			getProjectUrl(`/${id}/user}`),
			{ data: { employee_id } }
		);
		return response;
	},

	async changeProjectOwner(id: number, data: TProject.UserToProject) {
		const response = await axiosInstance.post<string>(
			getProjectUrl(`/${id}/change/owner`),
			data
		);
		return response;
	},

	async addComment(id: number, data: TProject.CommentRequest) {
		const response = await axiosInstance.post<string>(
			getProjectUrl(`/${id}/comment`),
			data
		);
		return response;
	},

	async generateFile(id: number) {
		const response = await axiosInstance.get<string>(
			getProjectUrl(`/${id}/generate/excel`)
		);
		return response;
	}
};

export default ProjectService;
