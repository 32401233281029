import { ChatVerify } from 'assets/ChatIcons';
import { useGetDialogs } from 'features/chat/api';
import { FC, useMemo } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { FormWrapper } from 'shared/ui/form/form-wrapper';
import { ReactSelect } from 'shared/ui/form/react-select';

interface IProps {
	isAdmin?: boolean;
	methods: UseFormReturn<FieldValues, any, undefined>;
}
export const DialogHeader: FC<IProps> = ({ methods, isAdmin }) => {
	const { data: dataDialogs } = useGetDialogs(isAdmin);

	const dialogsOptions = useMemo(() => {
		return (
			dataDialogs?.map(item => ({
				value: item.id,
				label: `${!item.last_message.is_read ? `•` : ''} ${item.first_name} ${
					item.last_name
				}
				`
			})) || []
		);
	}, [dataDialogs]);

	return (
		<>
			<div className='chat-header'>
				{isAdmin ? (
					<>
						<h1 style={{ marginBottom: '0px' }}>Панель админа для чата</h1>
						<FormWrapper methods={methods}>
							<ReactSelect
								control={methods.control}
								name='user'
								label={
									methods.watch('user')
										? 'Пользователь'
										: 'Выберите пользователя'
								}
								options={dialogsOptions}
							/>
						</FormWrapper>
					</>
				) : (
					<>
						<h1>
							Поддержка Kira
							<ChatVerify />
						</h1>
						<p>Онлайн 24 часа</p>
					</>
				)}
			</div>
		</>
	);
};
