import { FC } from 'react';
import { Rating } from 'shared/ui/rating';
import { RatingLine } from './rating-line';

interface IProps {
	reviewData: {
		review: string | number;
		reviews_count: number;
		rating: Record<number, number>;
	};
}

export const ReviewStats: FC<IProps> = ({ reviewData }) => {
	const ratingData = [5, 4, 3, 2, 1];
	return (
		<div className='raging-statistics'>
			<div className='raging-statistics__block-for-mob'>
				<div className='raging-statistics__header'>
					<Rating
						count={+reviewData?.review || 0}
						allCount={5}
						nextText={`${reviewData?.reviews_count || 0} отзыва`}
					/>
				</div>

				<div className='raging-statistics__note'>
					<p>
						Отзывы могут оставлять только те, у кого состоялась сделка. Так мы
						формируем честный рейтинг.
					</p>
				</div>
			</div>
			<div className='raging-statistics__count-reviews'>
				{reviewData.reviews_count} отзыва
			</div>

			<div className='raging-statistics-stars'>
				{ratingData.map(item => (
					<RatingLine
						key={item}
						count={reviewData?.rating?.[item] || 0}
						rating={item}
						allCount={reviewData?.reviews_count || 0}
					/>
				))}
			</div>
		</div>
	);
};
