import 'assets/Numbers';
import { FirstNumber, SecondNumber, ThirdNumber } from 'assets/Numbers';
import { HelpIcon } from 'assets/ProfileLogos';
import 'assets/input.scss';
import { useGetCompany } from 'features/company/api/useGetCompany';
import HelpModal from 'features/help/ui/help-modal';
import { useGetUser } from 'features/profile/api/useGetUser';
import { useState } from 'react';
import Dropdown from 'shared/ui/dropdown';
import ProfileInfoAdditional from '../additional';
import ProfileInfoCompany from '../company';
import ProfileInfoUser from '../user';
import './ProfileInfo.scss';

const ProfileInfo = () => {
	const { userData } = useGetUser();

	const { companyData } = useGetCompany(userData?.company_id);

	const [isModalVisible, setModalVisible] = useState(false);

	const openModal = () => {
		setModalVisible(true);
	};

	const closeModal = () => {
		setModalVisible(false);
	};

	return (
		<>
			<div className='profile-container'>
				<div className='warning-text'>
					<p>
						Пожалуйста, вводите достоверные данные, которые, при необходимости,
						сможете <br /> подтвердить документально
					</p>
					<p>
						<strong style={{ color: 'red' }}>*</strong> - обязательное поле для
						заполнения
					</p>
				</div>

				<Dropdown
					title='Личные данные'
					content={<ProfileInfoUser />}
					number={<FirstNumber />}
				/>
				<Dropdown
					title='Ваша компания'
					content={<ProfileInfoCompany companyInfo={companyData} />}
					number={<SecondNumber />}
				/>
				<Dropdown
					title='Дополнительные вопросы'
					content={<ProfileInfoAdditional additionalInfo={companyData} />}
					number={<ThirdNumber />}
				/>
				<div className='info-help' onClick={openModal}>
					<p>Нужна помощь в регистрации</p>
					<HelpIcon />
				</div>
			</div>
			<HelpModal isOpen={isModalVisible} onClose={closeModal} />
		</>
	);
};

export default ProfileInfo;
