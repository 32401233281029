import { useCompanyActions } from 'features/company/api/useCompanyActions';
import { useGetCountries, useGetLanguages } from 'features/utils/api';
import { FC, useEffect, useRef, useState } from 'react';
import { URL_SITE } from 'shared/constants';
import { useAlertMessage } from 'shared/hooks';
import { TCompany } from 'shared/services/types';
import { MultiSelectAdditional } from 'shared/ui/form/custom-select';
import { MyOptionType } from 'shared/ui/form/types';
import './ProfileInfoAdditional.scss';

interface IProps {
	additionalInfo?: TCompany.Response;
}

const ProfileInfoAdditional: FC<IProps> = ({ additionalInfo }) => {
	
	const [languagesData, setLanguagesData] = useState<MyOptionType[]>();
	const { addAlertMessage } = useAlertMessage();
	const [errors, setErrors] = useState('');

	const speakRef = useRef<any>([]);
	const correspondRef = useRef<any>([]);
	const { updateCompany } = useCompanyActions();
	const { data: countries } = useGetCountries();
	const { data: languages } = useGetLanguages();

	const fetchSelectData = async () => {
		const languagesResponseData = languages?.map(i => {
			const foundFlag = countries?.find(k => k.id === i.country_id);
			return {
				value: i.id,
				label: i.name,
				flag: URL_SITE + foundFlag?.flag
			};
		});
		setLanguagesData(languagesResponseData);
	};

	useEffect(() => {
		fetchSelectData();
	}, [countries, languages, additionalInfo]);

	useEffect(() => {
		if (additionalInfo) {
			speakRef.current = additionalInfo.speak_languages.map(v => v.id);
			correspondRef.current = additionalInfo.correspond_languages.map(
				v => v.id
			);
		}
	}, [additionalInfo]);

	const sendAdditional = async () => {
		let dataSpeak = [];
		let dataCorrespond = [];

		if (!!speakRef.current?.length || !!correspondRef.current?.length) {
			dataSpeak = speakRef.current?.map((i: any) => {
				return i.value || i.country_id;
			});
			dataCorrespond = correspondRef?.current?.map((i: any) => {
				return i.value || i.country_id;
			});
		}

		try {
			await updateCompany.mutateAsync({
				speak_languages_ids: dataSpeak.filter((item: any) => item),
				correspond_languages_ids: dataCorrespond.filter((item: any) => item)
			});
		} catch (error: any) {
			setErrors(error?.response?.data?.detail);
		}
	};

	const handleSpeakChange = (selectedOptions: any) => {
		speakRef.current = selectedOptions;
	};

	const handleCorrespondChange = (selectedOptions: any) => {
		correspondRef.current = selectedOptions;
	};

	useEffect(() => {
		const { isPending, isSuccess } = updateCompany;
		if (!isPending && isSuccess) {
			addAlertMessage('Сохранено', 'success');
		}
	}, [updateCompany.isPending, updateCompany.isSuccess, updateCompany.error]);

	return (
		<form
			onSubmit={e => {
				e.preventDefault();
				sendAdditional();
			}}
			className='profileadditional-form'
		>
			{errors && (
				<p
					className='supplier-label'
					style={{ position: 'relative', color: 'red' }}
				>
					{errors} <span>*</span>
					<div className='input-note'>
						<svg
							width='18'
							height='18'
							viewBox='0 0 18 18'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								d='M7.59375 6.65625C7.59375 5.8796 8.22335 5.25 9 5.25C9.77665 5.25 10.4062 5.8796 10.4062 6.65625C10.4062 7.17183 10.1288 7.62261 9.71502 7.86741C9.35853 8.07832 9 8.39829 9 8.8125V9.75'
								stroke='#777777'
								strokeLinecap='round'
							/>
							<circle cx='9' cy='12' r='0.75' fill='#777777' />
							<path
								d='M5.25 2.50337C6.35315 1.86523 7.63392 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 7.63392 1.86523 6.35315 2.50337 5.25'
								stroke='#777777'
								strokeLinecap='round'
							/>
						</svg>
					</div>
					<div className='input-note-desc'>Заполните форму выше</div>
				</p>
			)}

			{languagesData ? (
				<div className='select-block'>
					<p>На каком языке вы можете вести диалог?</p>
					<MultiSelectAdditional
						onChange={handleSpeakChange}
						multiSelectOptions={languagesData}
						defaultRef={speakRef.current}
					/>
				</div>
			) : (
				<div className='input-block'>
					<p>На каком языке вы можете вести диалог?</p>
					<input className='disabled' disabled />
				</div>
			)}
			{languagesData ? (
				<div className='select-block'>
					<p>На каком языке вы можете переписываться?</p>
					<MultiSelectAdditional
						onChange={handleCorrespondChange}
						multiSelectOptions={languagesData}
						defaultRef={correspondRef.current}
					/>
				</div>
			) : (
				<div className='input-block'>
					<p>На каком языке вы можете переписываться?</p>
					<input className='disabled' disabled />
				</div>
			)}

			<div className='block-save'>
				<button className='btn' type='submit'>
					Сохранить
				</button>
			</div>
		</form>
	);
};

export default ProfileInfoAdditional;
