import { axiosClassic } from 'shared/api'
import { getUtilsUrl } from 'shared/api/config'
import { TUtils } from './types/utils.types'

const UtilsService = {
	async getCountries() {
		const response = await axiosClassic.get<TUtils.DataCountry[]>(
			getUtilsUrl(`/countries`)
		)
		return response
	},

	async getCurrencies() {
		const response = await axiosClassic.get<TUtils.DataCurrencies[]>(
			getUtilsUrl(`/currencies`)
		)
		return response
	},

	async getCurrenciesExchange() {
		const response = await axiosClassic.get<TUtils.CurrenciesExchange>(
			getUtilsUrl(`/currencies/exchange`)
		)
		return response
	},

	async getDeliveryServices() {
		const response = await axiosClassic.get<TUtils.DataDelivery[]>(
			getUtilsUrl(`/delivery_services`)
		)
		return response
	},

	async getLanguages() {
		const response = await axiosClassic.get<TUtils.DataLanguage[]>(
			getUtilsUrl(`/languages`)
		)
		return response
	}
}

export default UtilsService
