import 'app/styles/modal.scss';
import { useAuthActions } from 'features/auth/api/useAuthActions';
import { FC } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useAlertMessage, useQueryParams } from 'shared/hooks';
import { TAuth } from 'shared/services/types';
import { FormWrapper } from 'shared/ui/form/form-wrapper';
import { Input } from 'shared/ui/form/input';

interface IProps {
	isOpen: boolean;
	onClose: () => void;
}

export const PasswordRecoveryConfirmModal: FC<IProps> = ({
	isOpen,
	onClose
}) => {
	const methods = useForm();
	const { token } = useQueryParams();

	const { addAlertMessage } = useAlertMessage();

	const { onRecoveryPassword } = useAuthActions();

	const openModalCode = async (dataForm: FieldValues) => {
		try {
			await onRecoveryPassword.mutateAsync({
				data: dataForm as TAuth.RecoveryPassword,
				token
			});
			addAlertMessage('Пароль успешно изменен', 'success');
			onClose();
		} catch (error: any) {
			addAlertMessage(error, 'error');
		}
	};

	return (
		<>
			<div className={`modal ${isOpen ? 'show' : 'hide'}`}>
				<div className='modal__window'>
					<div className='modal__header'>
						<h3>Введите новый пароль</h3>
						<button className='modal__close' onClick={onClose}>
							<svg
								width='32'
								height='32'
								viewBox='0 0 32 32'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M19.75 12.25L12.25 19.75M12.25 12.25L19.7499 19.75'
									stroke='#8A7DE7'
									strokeWidth='1.5'
									strokeLinecap='round'
								/>
								<path
									d='M31 16C31 23.0711 31 26.6066 28.8033 28.8033C26.6066 31 23.0711 31 16 31C8.92893 31 5.3934 31 3.1967 28.8033C1 26.6066 1 23.0711 1 16C1 8.92893 1 5.3934 3.1967 3.1967C5.3934 1 8.92893 1 16 1C23.0711 1 26.6066 1 28.8033 3.1967C30.2639 4.65731 30.7533 6.70983 30.9173 10'
									stroke='#8A7DE7'
									strokeWidth='1.5'
									strokeLinecap='round'
								/>
							</svg>
						</button>
					</div>
					<FormWrapper
						methods={methods}
						className='modal__form'
						onSubmit={openModalCode}
					>
						<Input
							placeholder='Пароль'
							control={methods.control}
							label='Пароль'
							isPassword
							required
							name='new_password1'
							error={methods.formState.errors}
							rules={{ required: 'Обязательное поле для заполнения' }}
						/>

						<Input
							placeholder='Повторите пароль'
							control={methods.control}
							label='Повторите пароль'
							required
							isPassword
							name='new_password2'
							error={methods.formState.errors}
							rules={{
								required: 'Обязательное поле для заполнения',
								validate: value => {
									const { new_password1 } = methods.getValues();
									return value === new_password1 || 'Пароли не совпадают';
								}
							}}
						/>

						<button
							type='submit'
							className='btn'
							disabled={onRecoveryPassword.isPending}
						>
							Изменить пароль
						</button>
					</FormWrapper>
				</div>
			</div>
		</>
	);
};
