import { Layout } from 'shared/ui/layout';
import { ProjectsPage } from 'widgets/projects';

const Projects = () => {
	return (
		<Layout>
			<ProjectsPage />
		</Layout>
	);
};

export default Projects;
