import { axiosInstance } from 'shared/api';
import { getChatUrl } from 'shared/api/config';
import { toFormData } from 'shared/utils';
import { TChat } from './types/chat.types';

const ChatService = {
	async getAllDialogs() {
		const response = await axiosInstance.get<TChat.UserDialogs[]>(
			getChatUrl('/dialog')
		);

		return response;
	},

	async getMessages(data: TChat.DialogRequest) {
		const response = await axiosInstance.get<TChat.DialogResponse>(
			getChatUrl('/dialog/message'),
			{ params: data }
		);
		return response;
	},

	async getAttachFile(data: TChat.AttachFileMessage) {
		const { message_id, ...dataRest } = data;

		const response = await axiosInstance.patch<{ file: string }>(
			getChatUrl(`/file`),
			toFormData(dataRest),
			{
				params: { message_id }
			}
		);

		return response;
	}
};

export default ChatService;
