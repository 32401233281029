import {
	EmployeesChangeIcon,
	EmployeesDeleteIcon,
	EmployeesUserIcon
} from 'assets/EmployeesIcon';
import { FC, useState } from 'react';
import { TCompany } from 'shared/services/types';
import { tokenParse } from 'shared/utils';
import { ChangeUserPopup, DeleteUserPopup } from './slices';

interface IProps {
	data: TCompany.EmployeesCompany;
}

const EmployeeComponent: FC<IProps> = ({ data }) => {
	const { is_boss } = tokenParse();
	const [open, setOpen] = useState(false);
	const [open1, setOpen1] = useState(false);
	return (
		<>
			<div className='employees-list-user'>
				<div className='employees-list-user-info'>
					<EmployeesUserIcon />
					<div>
						<p>
							{data.last_name + ' ' + data.first_name}
							<span className='indicator online'></span>
						</p>
						<p>{data.email}</p>
					</div>
				</div>
				<div
					style={{ display: is_boss ? '' : 'none' }}
					className='employees-list-user-icons'
				>
					<button
						type='button'
						disabled={!is_boss}
						onClick={() => setOpen(!open)}
					>
						<EmployeesChangeIcon />
					</button>
					<button
						type='button'
						disabled={!is_boss}
						onClick={() => setOpen1(!open1)}
					>
						<EmployeesDeleteIcon />
					</button>
				</div>
			</div>
			<ChangeUserPopup
				data={data}
				isOpen={open}
				onClose={() => setOpen(false)}
			/>

			<DeleteUserPopup
				onClose={() => setOpen1(false)}
				isOpen={open1}
				id={data.id}
			/>
		</>
	);
};

export default EmployeeComponent;
