import React, { FC, useEffect, useState } from 'react'
import './ClosedDropdown.scss'
import clsx from 'clsx'

interface IProps {
	id?: number
	data: any
	parent_data?: any
	handleSelectedItem?: any
}

const ClosedDropdown: FC<IProps> = ({
	id,
	data,
	parent_data,
	handleSelectedItem
}) => {
	const [isOpen, setOpen] = useState(false)
	const [items, setItem] = useState(data)
	const [selectedItem, setSelectedItem] = useState<number | null>(null)

	const transferSelectedItem = () => {
		handleSelectedItem(selectedItem)
	}

	const toggleDropdown = () => setOpen(!isOpen)

	const handleItemClick = (id: number) => {
		selectedItem === id ? setSelectedItem(null) : setSelectedItem(id)
		setOpen(!isOpen)
	}

	useEffect(() => {
		transferSelectedItem()
	}, [selectedItem])

	useEffect(() => {
		if (parent_data) {
			setSelectedItem(parent_data)
		}
	}, [parent_data])

	return (
		<div className={`closed-dropdown ${isOpen && 'open'}`}>
			<div className='closed-dropdown-header' onClick={toggleDropdown}>
				{selectedItem && items
					? items.find((item: any) => item.id === +selectedItem)?.label
					: '- корень -'}
				<svg
					className={clsx(isOpen && 'open')}
					xmlns='http://www.w3.org/2000/svg'
					width='18'
					height='18'
					viewBox='0 0 18 18'
					fill='none'
				>
					<path
						d='M14.25 8.75L9 13.25L3.75 8.75'
						stroke='#333333'
						strokeWidth='1.5'
						strokeLinecap='round'
						strokeLinejoin='round'
					/>
				</svg>
			</div>
			<div className={`closed-dropdown-body ${isOpen && 'open'}`}>
				{items ? (
					items.map((item: any) => (
						<div
							className='closed-dropdown-item'
							//@ts-ignore
							onClick={e => handleItemClick(e.target.id)}
							id={item.id}
						>
							<span
								className={`closed-dropdown-item-dot ${
									item.id === selectedItem && 'selected'
								}`}
							>
								•{' '}
							</span>
							{item?.label}
						</div>
					))
				) : (
					<></>
				)}
			</div>
		</div>
	)
}

export default ClosedDropdown
