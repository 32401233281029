import { axiosInstance } from 'shared/api';
import { getStatsUrl } from 'shared/api/config';
import { TStats } from './types/stats.types';

const StatsService = {
	async getCountProductPartner(id: number, data?: TStats.Filter) {
		const response = await axiosInstance.get<TStats.ByDate[]>(
			getStatsUrl(`/${id}/product/count/`),
			{
				params: data
			}
		);
		return response;
	},
	async getViewProductPartner(id: number, data?: TStats.Filter) {
		const response = await axiosInstance.get<TStats.Source[]>(
			getStatsUrl(`/${id}/product/views/`),
			{
				params: data
			}
		);
		return response;
	},
	async getViewPartner(id: number, data?: TStats.Filter) {
		const response = await axiosInstance.get<TStats.Source[]>(
			getStatsUrl(`/${id}/views/`),
			{
				params: data
			}
		);
		return response;
	}
};

export default StatsService;
