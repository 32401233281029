import { Layout } from 'shared/ui/layout';
import SuppliersDetail from 'widgets/suppliers/[id]';

const SupplierPage = () => {
	return (
		<Layout>
			<SuppliersDetail />
		</Layout>
	);
};

export default SupplierPage;
