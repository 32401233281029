import { CloseIcon, SendIcon } from 'assets/ChatIcons';
import { FC, useState } from 'react';
import FileUploadButton from 'shared/ui/form/button-file';

interface IProps {
	onSubmit: () => void;
	clearFile: () => void;
	chatFile: any;
	disabled?: boolean;
	message: string;
	setMessage: React.Dispatch<React.SetStateAction<string>>;
	handleFileUpload: (file: File) => void;
}
export const DialogFooter: FC<IProps> = ({
	chatFile,
	onSubmit,
	disabled,
	clearFile,
	message,
	setMessage,
	handleFileUpload
}) => {
	const [isClear, setIsClear] = useState(false);
	const onClearFile = () => {
		clearFile();
		setIsClear(true);
	};
	return (
		<div className='chat-footer'>
			<FileUploadButton
				onChange={e => {
					handleFileUpload(e);
					setIsClear(false);
				}}
				isClear={isClear}
			/>
			<div className='chat-input-block'>
				<div className='chat-input'>
					<form
						onSubmit={e => {
							e.preventDefault();
							onSubmit();
						}}
					>
						<input
							value={message}
							onChange={e => setMessage(e.target.value)}
							placeholder='Введите сообщение...'
						/>

						<button disabled={disabled}>
							<SendIcon />
						</button>
					</form>
				</div>
				{chatFile && (
					<>
						<p
							style={{
								marginTop: '10px',
								paddingLeft: '2%',
								display: 'flex',
								alignItems: 'center',
								gap: '10px'
							}}
						>
							{chatFile.name}{' '}
							<span
								onClick={onClearFile}
								style={{ cursor: 'pointer', height: '20px' }}
							>
								<CloseIcon />
							</span>
						</p>
					</>
				)}
			</div>
		</div>
	);
};
