import { useMutation } from '@tanstack/react-query';
import SubscriptionService from 'shared/services/subscription.service';
import { TSubscription } from 'shared/services/types/subscription.types';

export const useTariffsActions = () => {
	const updateTariff = useMutation({
		mutationFn: (data: TSubscription.Subscription) =>
			SubscriptionService.updateSubscription(data),
		mutationKey: ['update tariff']
	});

	const requestTariff = useMutation({
		mutationFn: (data: TSubscription.Subscription) =>
			SubscriptionService.requestSubscription(data),
		mutationKey: ['request tariff']
	});

	return { updateTariff, requestTariff };
};
