import { axiosInstance } from 'shared/api';
import { getPriceListUrl } from 'shared/api/config';
import { toFormData } from 'shared/utils';
import { TPrice } from './types/price.types';

const PricesService = {
	async getPriceSource() {
		const response = await axiosInstance.get<TPrice.Source[]>(
			getPriceListUrl(`/source`)
		);
		return response;
	},

	async addPriceSource(data: TPrice.SourceData) {
		const response = await axiosInstance.post<TPrice.Source>(
			getPriceListUrl(`/source`),
			data
		);
		return response;
	},

	async changePriceSource(id: number, data: TPrice.SourceData) {
		const response = await axiosInstance.patch<TPrice.Source>(
			getPriceListUrl(`/source/${id}`),
			data
		);
		return response;
	},
	async deletePriceSource(id: number) {
		const response = await axiosInstance.delete<TPrice.Source>(
			getPriceListUrl(`/source/${id}`)
		);
		return response;
	},

	async uploadPriceSource(id: number, data: TPrice.Update) {
		const response = await axiosInstance.post<TPrice.Source>(
			getPriceListUrl(`/source/${id}/upload`),
			toFormData(data)
		);
		return response;
	},

	async getHistorySource(id: number) {
		const response = await axiosInstance.get<TPrice.HistorySource[]>(
			getPriceListUrl(`/source/${id}/history`)
		);
		return response;
	},

	async uploadHistorySource(id: number, history_id: number) {
		const response = await axiosInstance.delete<TPrice.Source>(
			getPriceListUrl(`/source/${id}/history/${history_id}`)
		);
		return response;
	}
};

export default PricesService;
