import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ReviewService } from 'shared/services';
import SupplierService from 'shared/services/supplier.service';
import { TReview } from 'shared/services/types';
import { TSupplier } from 'shared/services/types/supplier.types';

export const useReviewById = (id: number) => {
	const queryClient = useQueryClient();

	const deleteReview = useMutation({
		mutationFn: () => ReviewService.deleteReview(id),
		mutationKey: ['delete review', id],
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: [`get reviews list`] });
		}
	});

	const addAnswerReview = useMutation({
		mutationFn: (data: TSupplier.ReviewsData) =>
			SupplierService.addReviewAnswer(id, data),
		mutationKey: ['answer review', id],
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: [`get reviews list`] });
		}
	});

	const changeReviewScore = useMutation({
		mutationFn: (data: TReview.ReactionUpdate) =>
			ReviewService.putAReaction(data),
		mutationKey: ['change score review'],
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: [`get reviews list`] });
		}
	});

	return { deleteReview, addAnswerReview, changeReviewScore };
};
