import { FolderIcon } from 'assets/ProductsIcon';
import clsx from 'clsx';
import { FoldersProjectSelect } from 'entities/folder-project-select';
import { useGetProject, useProjectsActions } from 'features/projects/api';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import Popup from 'reactjs-popup';
import { useAlertMessage } from 'shared/hooks';
import { TProduct, TProject } from 'shared/services/types';

interface IProps {
	dataProduct?: TProduct.Data;
	isBlur?: boolean;
}
export const ProductToProjectPopup: FC<IProps> = ({ dataProduct, isBlur }) => {
	const navigate = useNavigate();
	const { addAlertMessage } = useAlertMessage();
	const [open, setOpen] = useState(false);
	const { addProductProject, createProject } = useProjectsActions();
	const { data, isError, error } = useGetProject();
	const [createProjects, setCreateProjects] = useState(false);
	const [projectData, setProjectData] = useState<TProject.ProjectResponse>();
	const [idFolder, setIdFolder] = useState<number | null>(null);
	const [nameProject, setNameProject] = useState('');

	const createNAdd = async () => {
		try {
			const { data } = await createProject.mutateAsync({
				name: nameProject
			});
			productToProject(data.id);
		} catch (error: any) {
			addAlertMessage(error, 'error');
		}
	};

	const toggleCreateProjects = () => {
		setCreateProjects(!createProjects);
	};

	const togglePopup = () => {
		if (isBlur) return navigate('/account/tariffs');
		setOpen(!open);
	};

	const handlePopupClose = () => {
		setOpen(false);
		setIdFolder(null);
		setCreateProjects(false);
	};

	const getFolderProjects = async () => {
		if (isError) return addAlertMessage(error, 'error');
		setProjectData(data);
	};

	const productToProject = async (id?: number) => {
		//TODO
		const productId = dataProduct?.id;
		const idProduct = id || idFolder;
		if (!idProduct) return null;

		try {
			await addProductProject.mutateAsync({
				data: {
					count: 1,
					product_supplier_id: productId!
				},
				id: idProduct
			});
			handlePopupClose();
			addAlertMessage('Продукт добавлен в папку', 'success');
		} catch (error: any) {
			addAlertMessage(error, 'error');
		}
	};

	useEffect(() => {
		if (open) getFolderProjects();
	}, [open, data]);

	return (
		<>
			<td
				onClick={togglePopup}
				className={clsx('tolist-col manufacturer-col', 'tolist-button ')}
				aria-label='В список'
			>
				<>
					{isBlur ? (
						<div className='blur'>
							<p>{'Приобретите подписку'}</p>
						</div>
					) : (
						<p>{'Добавить'}</p>
					)}
				</>
			</td>
			<Popup
				className='producttoproject-popup-base'
				open={open}
				onClose={handlePopupClose}
			>
				<div>
					<div className='requisites-popup-header'>
						<h1>
							Добавить в проект
							<span className='note'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='18'
									height='18'
									viewBox='0 0 18 18'
									fill='none'
								>
									<path
										d='M7.59375 6.65625C7.59375 5.8796 8.22335 5.25 9 5.25C9.77665 5.25 10.4062 5.8796 10.4062 6.65625C10.4062 7.17183 10.1288 7.62261 9.71502 7.86741C9.35853 8.07832 9 8.39829 9 8.8125V9.75'
										stroke='#777777'
										strokeLinecap='round'
									/>
									<circle cx='9' cy='12' r='0.75' fill='#777777' />
									<path
										d='M5.25 2.50337C6.35315 1.86523 7.63392 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 7.63392 1.86523 6.35315 2.50337 5.25'
										stroke='#777777'
										strokeLinecap='round'
									/>
								</svg>
							</span>
							<span className='note-text'>
								Выберите проект или создайте новый, чтобы добавить товар в
								список
							</span>
						</h1>
						<button type='button' onClick={handlePopupClose}>
							<svg
								width='32'
								height='32'
								viewBox='0 0 32 32'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M19.75 12.25L12.25 19.75M12.25 12.25L19.7499 19.75'
									stroke='#8A7DE7'
									strokeWidth='1.5'
									strokeLinecap='round'
								/>
								<path
									d='M31 16C31 23.0711 31 26.6066 28.8033 28.8033C26.6066 31 23.0711 31 16 31C8.92893 31 5.3934 31 3.1967 28.8033C1 26.6066 1 23.0711 1 16C1 8.92893 1 5.3934 3.1967 3.1967C5.3934 1 8.92893 1 16 1C23.0711 1 26.6066 1 28.8033 3.1967C30.2639 4.65731 30.7533 6.70983 30.9173 10'
									stroke='#8A7DE7'
									stroke-width='1.5'
									stroke-linecap='round'
								/>
							</svg>
						</button>
					</div>
					<div className='producttoproject-popup-body'>
						<div className='producttoproject-popup-body__row'>
							{createProjects ? (
								<>
									<input
										type='text'
										placeholder='Назовите новый проект'
										required
										onChange={e => {
											setNameProject(e.target.value);
										}}
									/>
								</>
							) : (
								<FoldersProjectSelect
									projectData={projectData?.results}
									productToProject={setIdFolder}
								/>
							)}

							{/* @ts-ignore */}
							{dataProduct?.user_projects?.includes(idFolder) ? (
								<button
									style={{
										background: '#20BF55',
										border: 'none',
										cursor: 'default'
									}}
									disabled
								>
									<p>Добавлено</p>
									<FolderIcon color={'white'} height={'22'} width={'21'} />
								</button>
							) : idFolder ? (
								<button onClick={() => productToProject()}>
									<p>Добавить</p>
									<FolderIcon color={'white'} height={'22'} width={'21'} />
								</button>
							) : createProjects ? (
								<button onClick={createNAdd}>
									<p>Создать</p>
									<FolderIcon color={'white'} height={'22'} width={'21'} />
								</button>
							) : (
								<button onClick={toggleCreateProjects}>
									<p>Создать</p>
									<FolderIcon color={'white'} height={'22'} width={'21'} />
								</button>
							)}
							{}
						</div>
					</div>
				</div>
			</Popup>
		</>
	);
};
