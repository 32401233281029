import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import PartnerService from 'shared/services/partner.service';
import { TPartner } from 'shared/services/types/partner.types';

export const usePartnerByIdActions = (id: number) => {
	const queryClient = useQueryClient();

	const deleteCommentRequest = useMutation({
		mutationFn: () => PartnerService.deleteCommentRequest(id),
		mutationKey: ['deleteCommentRequest'],
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: [`partner info`] });
		}
	});

	const getInfoRequest = useQuery({
		queryFn: () => PartnerService.getInfoRequest(id),
		queryKey: ['getInfoRequest', id],
		select: ({ data }) => data
	});

	const updateInfoRequest = useMutation({
		mutationFn: (data: TPartner.RequestToPartnerUpdate) =>
			PartnerService.updateInfoRequest(id, data),
		mutationKey: ['updateInfoRequest'],
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: [`partner info`] });
		}
	});

	const deleteInfoRequest = useMutation({
		mutationFn: () => PartnerService.deleteInfoRequest(id),
		mutationKey: ['deleteInfoRequest'],
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: [`partner info`] });
		}
	});

	return {
		deleteCommentRequest,
		getInfoRequest,
		updateInfoRequest,
		deleteInfoRequest
	};
};
