import { toFormData } from 'axios';
import { axiosClassic, axiosInstance } from 'shared/api';
import { getAuthUrl } from 'shared/api/config';
import { TAuth } from './../types';
import { removeTokensStorage, saveTokensStorage } from './auth.helpers';

const AuthService = {
	async register(data: TAuth.Register) {
		const response = await axiosClassic.post<any>(
			getAuthUrl('/register'),
			data
		);
		return response;
	},

	async login(data: TAuth.Login) {
		const response = await axiosClassic.post(
			getAuthUrl('/login'),
			toFormData(data),
			{ withCredentials: true }
		);
		saveTokensStorage(response.data);

		return response;
	},

	async refresh() {
		const response = await axiosInstance.get(getAuthUrl('/token/refresh'));
		saveTokensStorage(response.data);
		return response;
	},

	async logout() {
		const response = await axiosInstance.get(getAuthUrl('/logout'), {});
		removeTokensStorage();
		return response;
	},

	async changeEmail(data: TAuth.ChangeEmail) {
		const response = await axiosInstance.post(
			getAuthUrl('/email/change'),
			data
		);
		return response;
	},

	async confirmChangeEmail(params: TAuth.ConfirmChangeEmail) {
		const response = await axiosInstance.get(getAuthUrl('/email/change'), {
			params
		});
		return response;
	},

	async verifyEmail(url: string) {
		const response = await axiosInstance.post(getAuthUrl('/email/verify'), {
			confirm_url: url
		});
		return response;
	},

	async confirmVerifyEmail(token: string) {
		const response = await axiosInstance.get(getAuthUrl('/email/verify'), {
			params: token
		});
		return response;
	},

	async passwordChange(data: TAuth.PasswordChange) {
		const response = await axiosInstance.put(
			getAuthUrl('/password/change'),
			data
		);
		return response;
	},

	async sendRecoveryPassword(data: TAuth.ForgotPassword) {
		const response = await axiosClassic.post(
			getAuthUrl('/password/forgot'),
			data
		);
		return response;
	},

	async recoveryPassword(data: TAuth.RecoveryPassword, token: string) {
		const response = await axiosClassic.patch(
			getAuthUrl(`/password/forgot`),
			data,
			{ params: { token: token } }
		);
		return response;
	}
};

export default AuthService;
