import { useMutation, useQuery } from '@tanstack/react-query';
import HelpService from 'shared/services/help.service';

import { THelper } from 'shared/services/types/help.types';

export const useHelpAction = () => {
	const getFqa = useQuery({
		queryFn: () => HelpService.getFqa(),
		queryKey: ['getFqa'],
		select: ({ data }) => data,
		enabled: false
	});

	const addNewDistributer = useMutation({
		mutationFn: (data: THelper.IDistributorRequest) =>
			HelpService.addNewDistributer(data),
		mutationKey: ['addNewDistributer']
	});

	const sendFeedback = useMutation({
		mutationFn: (data: THelper.FeedbackRequest) =>
			HelpService.sendFeedback(data),
		mutationKey: ['sendFeedback']
	});

	return {
		addNewDistributer,
		sendFeedback,
		getFqa
	};
};
