export const EMAIL_REGEX =
	/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const URL_SITE =
	/^(https?:\/\/)?([a-zA-Z0-9-]+\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,6})?(:[0-9]{1,5})?(\/[^\s]*)?$/;

const NUMBER = /^-?\d+(\.\d+)?$/;
const INTEGER_NUMBER = /^-?(0|[1-9]\d*)$/;

export const PHONE_REGEX =
	/^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,17}(\s*)?$/;

export const REGEX_VERIFY = {
	EMAIL_REGEX,
	URL_SITE,
	PHONE_REGEX,
	NUMBER,
	INTEGER_NUMBER,
};
