import ClosedDropdown from 'entities/profile/profile-dropdown';
import { useGetProject, useProjectByIdActions } from 'features/projects/api';
import { FC, useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import { useAlertMessage } from 'shared/hooks';

interface IProps {
	isOpen?: boolean;
	setIsOpen: any;
	data: any;
}
export const ChangeProjectPopup: FC<IProps> = ({ isOpen, setIsOpen, data }) => {
	const { data: dataProject } = useGetProject(undefined, !isOpen);
	const { addAlertMessage } = useAlertMessage();
	const [projectsList, setProjectsList] = useState<any[]>();
	const [projectName, setProjectName] = useState('');
	const [selectedProject, setSelectedProject] = useState();
	const { updateProject } = useProjectByIdActions(data.id);

	const handlePopupClose = () => {
		setIsOpen(false);
	};

	const changeProjectName = (item: string) => {
		setProjectName(item);
	};

	useEffect(() => {
		getProjectsList(dataProject?.results);
	}, [dataProject]);

	const getProjectsList = async (dataList?: any[]) => {
		try {
			const parsedData = dataList
				?.map(i => {
					return {
						id: i.id,
						label: i.name
					};
				})
				.filter(item => item.label !== projectName);
			setProjectsList(parsedData);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		if (data) {
			setProjectName(data.name);
		}
	}, [data]);

	const changeProject = async () => {
		try {
			const changeProjectData = () => {
				const name = projectName !== data.name ? { name: projectName } : {};
				if (selectedProject) {
					return {
						...name,
						parent_id: selectedProject
					};
				} else {
					return {
						...name
					};
				}
			};
			await updateProject.mutateAsync(changeProjectData());
			handlePopupClose();
		} catch (error: any) {
			addAlertMessage(error, 'error');
		}
	};

	return (
		<Popup
			open={isOpen}
			closeOnDocumentClick
			onClose={handlePopupClose}
			className='modal-edit-project'
			contentStyle={{
				maxWidth: '600px',
				borderRadius: '15px',
				background: '#fff'
			}}
			overlayStyle={{
				background: 'rgba(0, 0, 0, 0.5)'
			}}
		>
			<div>
				<div className='requisites-popup-header'>
					<h1 style={{ fontSize: '120%' }}>Редактировать проект</h1>
					<button onClick={handlePopupClose}>&#10006;</button>
				</div>
				<div className='projectchange-popup-form'>
					<input
						value={projectName}
						onChange={e => changeProjectName(e.target.value)}
						type='text'
						placeholder='Назовите папку'
						required
					/>
					<ClosedDropdown
						id={data.id}
						data={projectsList}
						parent_data={data.parent_id}
						handleSelectedItem={setSelectedProject}
					/>
				</div>
				<button onClick={changeProject} className='projectchange-popup-button'>
					Изменить
				</button>
			</div>
		</Popup>
	);
};
