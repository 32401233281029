import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAlertMessage } from 'shared/hooks';
import { TUser } from 'shared/services/types';
import UserService from 'shared/services/user.service';

export const useUpdateUser = (id: number) => {
	const queryClient = useQueryClient();

	const { isPending, isError, mutate, mutateAsync, isSuccess, data, error } =
		useMutation({
			mutationFn: (data: TUser.Update) => {
				return UserService.updateUser(id, data);
			},
			mutationKey: ['update user', id],
			onSuccess: () => {
				queryClient.invalidateQueries({
					queryKey: [`get user`]
				});
				queryClient.invalidateQueries({
					queryKey: [`get users company`]
				});
			}
		});

	return {
		isPending,
		isError,
		error,
		mutate,
		isSuccess,
		data,
		updateUserAsync: mutateAsync
	};
};
