import { useQuery } from '@tanstack/react-query'
import SubscriptionService from 'shared/services/subscription.service'

export const useGetTariff = () => {
	const { isPending, isError, isSuccess, data, error } = useQuery({
		queryFn: () => SubscriptionService.getSubscriptionMe(),
		queryKey: ['sub tariffs'],
		retry: 0,
		select: ({ data }) => data
	})

	return {
		isPending,
		isError,
		isSuccess,
		data,
		tariffName: data?.tariff?.name || 'Бесплатный'
	}
}
