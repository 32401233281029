import { useMutation, useQueryClient } from '@tanstack/react-query';
import AuthService from 'shared/services/auth/auth.service';
import { TAuth } from 'shared/services/types/auth.types';

export const useAuthActions = () => {
	const queryClient = useQueryClient();

	const emailRecovery = useMutation({
		mutationFn: (data: TAuth.ForgotPassword) =>
			AuthService.sendRecoveryPassword(data),
		mutationKey: ['sendRecoveryPassword'],
		onSuccess: ({ data }) => {
			data;
		}
	});

	const passwordChange = useMutation({
		mutationFn: (data: TAuth.PasswordChange) =>
			AuthService.passwordChange(data),
		mutationKey: ['emailRecovery'],
		onSuccess: ({ data }) => {
			data;
		}
	});

	const onRecoveryPassword = useMutation({
		mutationFn: (data: { data: TAuth.RecoveryPassword; token: string }) =>
			AuthService.recoveryPassword(data.data, data.token),
		mutationKey: ['onRecoveryPassword']
	});

	const onRegister = useMutation({
		mutationFn: (data: TAuth.Register) => AuthService.register(data),
		mutationKey: ['register']
	});

	const onLogin = useMutation({
		mutationFn: (data: TAuth.Login) => AuthService.login(data),
		mutationKey: ['login']
	});

	const onSendRecoveryPassword = useMutation({
		mutationFn: (data: TAuth.ForgotPassword) =>
			AuthService.sendRecoveryPassword(data),
		mutationKey: ['onSendRecoveryPassword']
	});

	const onVerifyEmail = useMutation({
		mutationFn: (url: string) => AuthService.verifyEmail(url),
		mutationKey: ['onVerifyEmail']
	});

	const onConfirmChangeEmail = useMutation({
		mutationFn: (data: TAuth.ConfirmChangeEmail) =>
			AuthService.confirmChangeEmail(data),
		mutationKey: ['onConfirmChangeEmail'],
		onSuccess: ({ data }) => {
			queryClient.invalidateQueries({ queryKey: [`sub tariff`] });
			queryClient.invalidateQueries({ queryKey: [`get user`] });
			return data;
		}
	});

	return {
		emailRecovery,
		passwordChange,
		onRecoveryPassword,
		onRegister,
		onLogin,
		onSendRecoveryPassword,
		onConfirmChangeEmail,
		onVerifyEmail
	};
};
